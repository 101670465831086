import { TextField, styled } from '@mui/material';

export const SearchProductTextField = styled(TextField)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		'& label.MuiInputLabel-shrink+.MuiInputBase-root': {
			marginTop: theme.spacing(2),
		},
		'& label+.MuiInputBase-root': {
			marginTop: theme.spacing(2),
		},
		'& .MuiInputLabel-root': {
			fontSize: '1.5rem',
		},
		'& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
			paddingLeft: theme.spacing(4),
		},
		'& .MuiInputLabel-shrink': {
			display: 'none',
		},

		'& .MuiInputBase-input': {
			fontSize: '1.5rem',
		},
	},
}));
