import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentLocationDto } from 'api';
import FilledGeopin from 'assets/volvoIcons/Positioning/Geopin';
import { unitLocationUpdateEventToTranslationMapping } from 'domain/unit';
import { TextDisplay, TextDisplayProps } from 'library/components';
import { getLocalizedDateFormat } from 'library/formatters';
import { LocationText } from 'modules/Shared/Components/OperatingLocation/LocationText';

interface CountryCityDisplayProps {
	currentLocation?: CurrentLocationDto | null;
	withHelperText?: boolean;
	loading?: TextDisplayProps['loading'];
}

export const CountryCityDisplay = ({
	currentLocation,
	withHelperText = true,
	loading,
}: CountryCityDisplayProps): JSX.Element => {
	const { t } = useTranslation(['commonStatus', 'commonUnit']);
	const helperText = useMemo(() => {
		if (!withHelperText || !currentLocation) {
			return '';
		}
		const { updateEvent, utcUpdateDate } = currentLocation;
		const eventTrans =
			updateEvent &&
			unitLocationUpdateEventToTranslationMapping[updateEvent]
				? t(unitLocationUpdateEventToTranslationMapping[updateEvent])
				: updateEvent || '';

		return `${eventTrans} ${getLocalizedDateFormat(utcUpdateDate)}`;
	}, [t, currentLocation, withHelperText]);

	return (
		<TextDisplay
			label={t('commonUnit:location')}
			loading={loading}
			value={
				<LocationText
					countryCode={currentLocation?.country}
					city={currentLocation?.city}
				/>
			}
			helperText={helperText}
			startIcon={currentLocation ? null : <FilledGeopin />}
		/>
	);
};
