import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { reverse } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import { unitOtherPartsQuery } from 'api/queries';
import { getOtherPartId } from 'api/responses/models/Unit/UnitOtherPartsDto';
import { AreaName } from 'domain/constants';
import { SectionButton } from 'library/components';
import {
	NoDataAvailable,
	SectionTitleButtons,
} from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import { parseId } from 'library/utils/helpers/Unit';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { EditProductUnitDataGrid } from 'modules/UnitOverview/Components/EditProductUnitDataGrid';
import { DefaultProductUnitDataGrid } from 'modules/UnitOverview/Components/ProductUnitDataGrid';

const OtherProductsSectionPage = (): JSX.Element => {
	const { api } = useProductCenterApi();

	const { unitId } = useParams();
	const unitIdParsed = parseId(unitId);
	const { data } = useSuspenseQuery({
		...unitOtherPartsQuery(api, unitIdParsed),
		select: (data) => ({
			...data,
			unitOtherPartDtos: data.unitOtherPartDtos.map((product) => ({
				id: getOtherPartId(product),
				...product,
			})),
		}),
	});
	const [otherProductEdit, setOtherProductEdit] = useState<boolean>(false);
	const { t } = useTranslation([
		'unitOverview',
		'commonButton',
		'unitCreation',
	]);

	const otherParts = data?.unitOtherPartDtos ?? [];
	const hasData = otherParts && otherParts.length > 0;

	if (otherProductEdit) {
		return (
			<EditProductUnitDataGrid
				otherParts={otherParts}
				unitId={unitIdParsed}
				setOtherProductEdit={setOtherProductEdit}
			/>
		);
	}

	return (
		<>
			<SectionTitleButtons>
				<AuthorizeComponent
					areaName={AreaName.Unit}
					permission={OperationType.Write}>
					<SectionButton
						text={t('commonButton:edit')}
						icon={<Edit />}
						onClick={() => setOtherProductEdit(true)}
					/>
				</AuthorizeComponent>
			</SectionTitleButtons>
			{hasData ? (
				<>
					<DefaultProductUnitDataGrid
						otherParts={reverse([...otherParts])}
						isEditMode={false}
					/>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginTop: 3,
						}}>
						<ChangedByInfoDisplay changedByInfo={data?.changedBy} />
					</Box>
				</>
			) : (
				<NoDataAvailable />
			)}
		</>
	);
};

export default withAITracking(
	reactPlugin,
	OtherProductsSectionPage,
	'UnitOverviewOtherProductsSectionPage',
	'application-insight-container'
);
