import { PositionDto, RootPositionDto } from 'api';
import { isMPPurpose } from 'domain/unit';
import { getMatchedInstallations } from 'domain/unit/structure/GRfunctions';

export const findPosition = (
	id: string,
	position?: PositionDto | RootPositionDto
): PositionDto | RootPositionDto | undefined => {
	let childPosition;

	if (position?.idTemporary === id) {
		return position;
	} else if (!!position?.children) {
		for (let i = 0; i < position.children.length; i++) {
			childPosition = findPosition(id, position.children[i]);

			if (!!childPosition) {
				break;
			}
		}
	}

	return childPosition;
};

export const findAllMPPositions = (
	position?: PositionDto | RootPositionDto
): (PositionDto | RootPositionDto)[] =>
	getMatchedInstallations(position as PositionDto).filter((i) =>
		isMPPurpose(i.matchedInstallation!.installationPurposeKey)
	);
