import { Badge, Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignType, mapCampaignTypeToColor } from 'domain/campaign';
import { CampaignWarning } from 'modules/Shared/Components/CampaignWarning';

export interface CampaignBadgeProps {
	campaignType?: CampaignType | null;
}

export const CampaignBadge = ({
	campaignType,
	children,
}: PropsWithChildren<CampaignBadgeProps>): JSX.Element => {
	const { t } = useTranslation(['unitOverview']);
	return (
		<Badge
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			badgeContent={
				campaignType != null &&
				mapCampaignTypeToColor(campaignType) !== undefined ? (
					<Tooltip
						title={t(
							'unitOverview:unit-campaign-indicator-tooltip'
						)}
						arrow>
						<span>
							<CampaignWarning
								campaignType={campaignType}
								sx={{ fontSize: 'small' }}
							/>
						</span>
					</Tooltip>
				) : undefined
			}>
			{children}
		</Badge>
	);
};
