import { Typography } from '@mui/material';
import { noop } from 'lodash';
import { ReactElement, ReactNode, cloneElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespace, ParseKeys } from 'i18next';
import { useDialogState } from 'library/hooks';

import { AnchorProps } from '../buttons';
import { BaseDialog, BaseDialogProps } from './BaseDialog';

export interface ActionDialogChildProps {
	close: () => void;
	onConfirm: () => void;
}

export interface ActionDialogProps extends BaseDialogProps {
	Anchor: ReactElement<AnchorProps>;
	renderItem: (props: ActionDialogChildProps) => ReactNode;
	titleKey?: ParseKeys<Namespace>;
}

export const ActionDialog = ({
	Anchor,
	onClose = noop,
	onConfirm = noop,
	renderItem,
	title = '',
	titleKey,
	...restProps
}: ActionDialogProps) => {
	const { open, handleOpen, handleClose } = useDialogState(false);
	const { t } = useTranslation();

	const handleOnClose = useCallback(() => {
		onClose();
		handleClose();
	}, [handleClose, onClose]);

	return (
		<>
			{cloneElement(Anchor, { onClick: handleOpen })}
			{open && (
				<BaseDialog
					open={open}
					onClose={handleOnClose}
					{...restProps}
					onConfirm={onConfirm}>
					{titleKey && (
						<Typography variant="h3" sx={{ mb: 2 }}>
							{`${t(titleKey, {
								defaultValue: 'error-unexpected',
							})} ${title}`}
						</Typography>
					)}
					{renderItem({ close: handleOnClose, onConfirm })}
				</BaseDialog>
			)}
		</>
	);
};
