import { ChangedByInfoDto } from 'api';
import { CampaignType } from 'domain/campaign';
import { UnitStatus } from 'domain/unit';
import { joinTwoValuesWithCommaAndSpace } from 'library/formatters';

import { InstallationSubSystem } from './InstallationSubSystem';
import { MainSegment } from './MainSegment';
import { UnitIdentification } from './UnitIdentification';

export interface Unit {
	id: number;
	brandOrType: string | null;
	model: string | null;
	consumerLineOfBusiness: string | null;
	consumerUsingPurpose: string | null;
	mainSegment: MainSegment | null;
	identification: UnitIdentification | null;
	installations: Array<InstallationSubSystem>;
	status: UnitStatus | null;
	statusDate: Date | null;
	configurationChangedBy: ChangedByInfoDto | null;
	outstandingCampaignType: CampaignType | null;
	isBlueprint: boolean;
}

export const getUnitTitle = (
	name: UnitIdentification['name'] | undefined,
	brandOrType: Unit['brandOrType'] | undefined,
	model: Unit['model'] | undefined
) =>
	name ||
	(brandOrType ? joinTwoValuesWithCommaAndSpace(brandOrType, model) : '');

export const getProfilePicture = (imagesId: Array<number> | undefined) =>
	Array.isArray(imagesId) ? imagesId.at(0) : undefined;
