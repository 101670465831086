import { generatePath } from 'react-router-dom';

import { Link, LinkProps } from 'library/components/link';
import { UnitOverviewPageUrl } from 'main/routes/urls/RouteUrls';

interface UnitOverviewLinkProps extends Omit<LinkProps, 'to'> {
	unitId: number;
}

export const UnitOverviewLink = ({
	unitId,
	...props
}: UnitOverviewLinkProps): JSX.Element => (
	<Link
		className="pc-link"
		to={generatePath(UnitOverviewPageUrl, {
			unitId: unitId.toString(),
		})}
		{...props}
	/>
);
