import { PhotoCamera } from '@mui/icons-material';
import { Avatar, SxProps } from '@mui/material';

import { THUMBNAIL_SIZES, useApiDownloadImage } from 'api/queries';
import { Sxable } from 'library/types';

export const DEFAULT_AVATAR_IMG_NAME = 'unit-avatar';
export interface UnitAvatarProps extends Sxable {
	imageId?: number | null;
	name?: string;
	thumbnail?: boolean;
	withZoom?: boolean;
}

const zoomSx: SxProps = {
	transition: 'transform .125s linear',
	'&:hover': {
		transform: 'scale(1.2)',
	},
};

export const UnitAvatar = ({
	imageId,
	name = DEFAULT_AVATAR_IMG_NAME,
	sx = [],
	thumbnail = false,
	withZoom = false,
}: UnitAvatarProps): JSX.Element => {
	const source = useApiDownloadImage(
		imageId,
		thumbnail ? THUMBNAIL_SIZES.SMALL : undefined
	);

	return (
		<Avatar
			className="unit-avatar__root"
			src={source}
			alt={imageId?.toString()}
			slotProps={{
				img: {
					sx: withZoom && imageId ? zoomSx : {},
					name,
				},
			}}
			sx={[
				{
					bgcolor: 'grey.300',
					width: source ? 'auto' : undefined,
					objectFit: 'contain',
				},
				...(Array.isArray(sx) ? sx : [sx]),
			]}
			variant="square">
			<PhotoCamera />
		</Avatar>
	);
};
