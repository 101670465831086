import { SxProps, styled, useTheme } from '@mui/material';
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import { generatePath } from 'react-router-dom';

import { Link } from 'library/components/link';
import { Removable, Sxable } from 'library/types';
import { textOverflowSx } from 'library/utils/themes';
import { productIndividualviewPageUrl } from 'main/routes/urls/RouteUrls';

interface ProductIndividualLinkProps extends Removable, Sxable {
	className?: string;
	color?: string;
	isActive?: boolean;
	section?: string;
	serialNumber: string | null;
	target?: HTMLAttributeAnchorTarget;
}

const getNewPiViewUrl = (serialNumber?: string | null, section: string = '') =>
	generatePath(productIndividualviewPageUrl, {
		serialnumber: serialNumber ?? '',
		section,
	});

export const ProductIndividualLink = ({
	children,
	className,
	section,
	serialNumber,
	sx,
	target = '_self',
}: PropsWithChildren<ProductIndividualLinkProps>) =>
	!serialNumber ? null : (
		<Link
			className={className}
			sx={sx}
			target={target}
			to={getNewPiViewUrl(serialNumber, section)}>
			{children}
		</Link>
	);

const defaultProductIndividualTitleLinkSx: SxProps = {
	textDecoration: 'none',
	'&:hover': { textDecoration: 'underline' },
	...textOverflowSx,
};

export const ProductIndividualTitleLink = ({
	color,
	isActive = true,
	isRemoved,
	serialNumber,
	sx,
	title,
	...rest
}: ProductIndividualLinkProps & { title?: string }): JSX.Element => {
	const {
		palette: {
			grey,
			secondary: { main: secondaryMain },
		},
	} = useTheme();

	return isActive ? (
		<ProductIndividualLink
			serialNumber={serialNumber}
			sx={{ color: isRemoved ? grey[700] : secondaryMain, ...sx }}
			{...rest}>
			{title || serialNumber}
		</ProductIndividualLink>
	) : (
		<span>{serialNumber}</span>
	);
};

const StyledErrorLinksContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
});

interface ProductIndividualTitleLinksActionProps extends Sxable {
	serialNumbers: string[];
	title: string;
}

export const ProductIndividualTitleLinksAction = ({
	sx = defaultProductIndividualTitleLinkSx,
	serialNumbers,
	title,
}: ProductIndividualTitleLinksActionProps) => {
	const {
		palette: {
			text: { primary: textPrimary },
		},
	} = useTheme();

	return (
		<StyledErrorLinksContainer>
			{serialNumbers.map((serialNumber) => (
				<ProductIndividualTitleLink
					className="pc-link"
					key={serialNumber}
					sx={{
						...sx,
						color: textPrimary,
						textTransform: 'uppercase',
					}}
					serialNumber={serialNumber}
					title={title}
				/>
			))}
		</StyledErrorLinksContainer>
	);
};
