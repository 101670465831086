export const MAX_DECIMAL = Number.MAX_SAFE_INTEGER;
export const MAX_SAFE_FLOAT = 9999999999999;

export const COMMENT_BOX_MAX_LENGTH = 2000;

export const MAX_TRAVEL_EXPENSES_HOURS = 6;
export const MAX_TRAVEL_EXPENSES_DISTANCE_IN_KM = 300;
export const MAX_TRAVEL_EXPENSES_DISTANCE_IN_MILES = 180;
export const MAX_TRAVEL_DESCRIPTION_LENGTH = 200;

export const MAX_TEXT_LENGTH = 255; // number of characters

export const DecimalNumberUnitMaskProps = {
	mask: Number,
	scale: 3,
	signed: 'false',
	thousandsSeparator: '',
	radix: '.',
	mapToRadix: ['.', ','],
	min: 0,
	max: MAX_DECIMAL,
};

export const IntigerNumberUnitMaskProps = {
	mask: Number,
	scale: 0,
	signed: 'false',
	thousandsSeparator: '',
	type: 'tel',
	unmask: 'typed',
	min: 0,
	max: MAX_DECIMAL,
};
