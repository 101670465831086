import { Close } from '@mui/icons-material';
import {
	Box,
	IconButton,
	Drawer as MUIDrawer,
	SxProps,
	Typography,
	useTheme,
} from '@mui/material';
import { ReactElement } from 'react';

import { PropWithTestId, PropsWithChildren, Sxable } from 'library/types';

export const DRAWER_SIDE_SPACE = 4;

export const DrawerContainer = ({ children }: PropsWithChildren) => (
	<Box
		sx={{
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',

			width: (theme) => ({
				xs: '100vw',
				sm: '70vw',
				md: theme.spacing(80),
			}),
		}}>
		{children}
	</Box>
);

interface CloseButtonProps {
	handleClose: () => void;
}

export const CloseButton = ({ handleClose }: CloseButtonProps) => (
	<IconButton
		onClick={handleClose}
		sx={(theme) => ({
			position: 'absolute',
			right: theme.spacing(2),
			top: theme.spacing(2),
			background: '#FFFFFF70',
			zIndex: 1,
			'&:hover': {
				background: '#FFFFFF90',
			},
		})}>
		<Close />
	</IconButton>
);

interface DrawerProps extends PropsWithChildren, Sxable, PropWithTestId {
	childrenSx?: SxProps;
	handleClose: () => void;
	title: string | ReactElement;
}

export const Drawer = ({
	children,
	childrenSx = { p: DRAWER_SIDE_SPACE, pt: 0 },
	dataTestId,
	handleClose,
	sx,
	title,
}: DrawerProps) => {
	const {
		palette: {
			gray: { main },
		},
	} = useTheme();

	return (
		<MUIDrawer
			data-testid={dataTestId}
			open
			anchor="right"
			PaperProps={{ sx }}
			onClose={handleClose}>
			<DrawerContainer>
				<CloseButton handleClose={handleClose} />
				<Box sx={{ backgroundColor: main, paddingY: 3 }}>
					<Typography
						variant="h6"
						sx={{
							marginLeft: 4,
							marginRight: 8,
						}}>
						{title}
					</Typography>
				</Box>
				<Box
					sx={[
						{
							overflow: 'auto',
						},
						...(Array.isArray(childrenSx)
							? childrenSx
							: [childrenSx]),
					]}>
					{children}
				</Box>
			</DrawerContainer>
		</MUIDrawer>
	);
};
