import { useQuery } from '@tanstack/react-query';

import { useProductCenterApi } from 'api';
import { countryInfosQuery } from 'api/queries';
import CountryFlag from 'library/components/controls/display/CountryFlag';
import { getShortLocationFormat } from 'library/formatters';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

const COUNTRY_NAME_FORMAT_LONG = 'long';

export interface LocationTextProps {
	city?: string | null;
	countryCode?: string | null;
	countryNameFormat?: 'short' | 'long';
	showCountryFlag?: boolean;
}

export const LocationText = ({
	city,
	countryCode,
	countryNameFormat = COUNTRY_NAME_FORMAT_LONG,
	showCountryFlag = true,
}: LocationTextProps): JSX.Element => {
	const { api } = useProductCenterApi();

	const languageCode = useAppSelector(selectUser).language;
	const { data } = useQuery({
		...countryInfosQuery(api, languageCode),
		enabled: countryNameFormat === COUNTRY_NAME_FORMAT_LONG,
	});

	const countryName =
		data?.find(({ alpha2 }) => alpha2 === countryCode)
			?.translatedCountryName || countryCode;

	return (
		<>
			{showCountryFlag && countryCode && (
				<CountryFlag countryCode={countryCode} />
			)}

			{city != null
				? getShortLocationFormat(countryName, city)
				: countryName}
		</>
	);
};
