import { Button, ButtonOwnProps } from '@mui/material';
import { HTMLAttributeAnchorTarget, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ArrowRight } from 'assets/volvoIcons';
import { Id } from 'domain/Id';
import { ServiceProtocolStatus } from 'domain/serviceProtocol';
import {
	getLinkOpenInitiatedServiceProtocol,
	getLinkOpenServiceProtocolView,
} from 'library/components/link';

interface ServiceProtocolDetailsLinkProps
	extends Id,
		Pick<ButtonOwnProps, 'color' | 'variant'> {
	Icon?: ReactNode;
	status: ServiceProtocolStatus;
	drivelineId: number;
	linkTarget?: HTMLAttributeAnchorTarget | undefined;
}

export const ServiceProtocolDetailsLink = memo(
	({
		color,
		drivelineId,
		Icon = <ArrowRight />,
		id,
		status,
		variant = 'text',
		linkTarget = '_self',
	}: ServiceProtocolDetailsLinkProps) => {
		const { t } = useTranslation(['commonButton']);
		const isInitiated = status === 'Initiated';
		const serviceProtocolPath = isInitiated
			? getLinkOpenInitiatedServiceProtocol(id, drivelineId)
			: getLinkOpenServiceProtocolView(id);

		return (
			<Button
				component={Link}
				color={color}
				endIcon={Icon}
				target={linkTarget}
				to={serviceProtocolPath}
				sx={{ mr: 2 }}
				variant={variant}>
				{isInitiated ? t('commonButton:edit') : t('commonButton:view')}
			</Button>
		);
	}
);

ServiceProtocolDetailsLink.displayName = 'ServiceProtocolDetailsLink';
