import {
	CreateOrUpdateConsumer,
	EndConsumerDto,
	EndConsumersSearchResponse,
} from 'api';

import { Customer } from '../State';

export function mapEndCustomerFormDataToRequestData(
	values: Customer
): CreateOrUpdateConsumer {
	if (!values.isConsumerAgreeForPentaStorageOfUserInformation) {
		return {
			id: values.id,
			consumerType: values.consumerType,
			countryCode: values.countryCode ?? '',
			city: values.city ?? '',
			zipCode: values.zipCode ?? '',
			isConsumerAgreeForPentaStorageOfUserInformation:
				values.isConsumerAgreeForPentaStorageOfUserInformation,
			notificationLanguage: values.notificationLanguage ?? '',
		};
	}

	return {
		...values,
		id: values.id,
		countryCode: values.countryCode ?? '',
		city: values.city ?? '',
		zipCode: values.zipCode ?? '',
		notificationLanguage: values.notificationLanguage ?? '',
		companyName: values.companyName,
		vatNumber: values.vatNumber,
	};
}

export function getNewSearchResults(
	searchData: EndConsumersSearchResponse | null,
	updatedCustomer: EndConsumerDto,
	wasDeleted: boolean
): EndConsumersSearchResponse | null {
	if (!searchData?.results) {
		return searchData;
	}

	let newData = searchData.results;

	if (wasDeleted) {
		newData = newData.filter(
			(customer) => customer.id !== updatedCustomer.id
		);
	} else {
		newData = newData.map((item) =>
			item.id === updatedCustomer.id ? updatedCustomer : item
		);
	}

	return {
		results: newData,
		resultMoreThan10: searchData.resultMoreThan10,
	};
}
