import { Button, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ProductIndividualItemPurpose } from 'api';
import { ShoppingCart } from 'assets/volvoIcons/Misc';
import { getVolvoShopPartsBySNUrl } from 'library/components/link';

export const PartShopLink = ({
	serialNumber,
	isIcon = false,
}: Pick<ProductIndividualItemPurpose, 'serialNumber'> & {
	isIcon?: boolean;
}) => {
	const { t } = useTranslation(['productIndividual']);

	return (
		<Tooltip title={t('productIndividual:tooltip-parts-info-button')}>
			{isIcon ? (
				<IconButton
					component={Link}
					to={getVolvoShopPartsBySNUrl(serialNumber)}
					target="_blank"
					size="small"
					color="secondary">
					<ShoppingCart />
				</IconButton>
			) : (
				<Button
					component={Link}
					startIcon={<ShoppingCart />}
					to={getVolvoShopPartsBySNUrl(serialNumber)}
					target="_blank"
					variant="text">
					{t('productIndividual:parts-info-button')}
				</Button>
			)}
		</Tooltip>
	);
};
