import {
	DetailedSegment,
	DrivelineGR,
	DrivelineMatch,
	InstallationGR,
	InstallationMatch,
	InstallationType,
	PartForSaleGR,
	PartForSaleMatch,
	PositionDto,
	PositionGR,
	ProductIndividual,
	ProductIndividualMatch,
	RootPositionGR,
	UnitInfo,
} from 'api';
import { convertWeightToKilograms } from 'api/responses/models/Unit/UnitMeasurement';
import { getBrand, getModel } from 'domain/unit/brandAndModel';
import { isIsoHullId, isUsCoastGuardHullId } from 'domain/unit/hullIdSelector';

import { GuidedRegistrationState } from './Types';

export const mapRegistrationTreeModelToCommand = (
	state: GuidedRegistrationState
): RootPositionGR => {
	const treeRoot = state.model?.tree;

	if (treeRoot == null) {
		throw new Error('Missing tree model');
	}

	const { blueprintSystemConfigurationId, ...position } = treeRoot;
	const mappedPosition = mapPosition(position as PositionDto);

	const unitInfo = mapUnitStateToUnitInfo(state);

	return {
		...mappedPosition,
		blueprintSystemConfigurationId,
		// manually add unit info data to the root position
		unitInfo,
	};
};

const mapPosition = (position: PositionDto): PositionGR => ({
	blueprintConfigurationId: position.blueprintConfigurationId,
	chassis: position.matchedChassis,
	driveline: mapDriveline(position.matchedDriveline),
	installation: mapInstallation(position.matchedInstallation),
	module: position.matchedModule,
	productIndividual: mapProductIndividual(position.matchedProductIndividual),
	otherProduct: mapPartForSales(position),
	children: position.children?.map(mapPosition) ?? null,
	unitInfo: null, // only on root position
});

const mapDriveline = (driveline: DrivelineMatch | null): DrivelineGR | null =>
	driveline
		? {
				drivelineId: driveline.drivelineId,
				position: driveline.drivelinePosition,
			}
		: null;

const mapInstallation = (
	installation: InstallationMatch | null
): InstallationGR | null =>
	installation
		? {
				blueprintMasterIdentifier: null,
				detailedSegment:
					installation.detailedSegmentKey as DetailedSegment,
				installationPurpose:
					installation.installationPurposeKey as InstallationType,
				operatingProfile: installation.operatingProfileKey,
			}
		: null;

const mapPartForSales = (position: PositionDto): PartForSaleGR[] | null =>
	[
		position.matchedSerializedPartForSale,
		...(position.matchedNonSerializedPartForSales ?? []),
	]
		.filter((x) => !!x)
		.map(mapOtherPart);

const mapOtherPart = (part: PartForSaleMatch): PartForSaleGR => ({
	...part,
	installed: part.quantity,
	scipNumber: part.scipNumber
});

const mapProductIndividual = (
	product: ProductIndividualMatch | null
): ProductIndividual | null =>
	product
		? {
				...product,
				isNonPentaProduct: false, // for the GR this is always false atm
				description: null, // is used only for non penta product
				type: product.productCategory,
			}
		: null;

const mapUnitStateToUnitInfo = (
	unitState: GuidedRegistrationState
): UnitInfo => {
	const weight = parseFloat(unitState.unitData.displacement);
	const unitOfWeight = unitState.unitData.displacementUnit;

	return {
		mainSegment: unitState.unitMetadata.mainSegment,
		lineOfBusiness: unitState.unitMetadata.lineOfBusiness ?? '',
		purpose: unitState.unitMetadata.unitPurpose ?? '',
		brand: getBrand(
			unitState.unitData.brand,
			unitState.unitData.customBrand
		),
		model: getModel(
			unitState.unitData.model,
			unitState.unitData.customModel
		),
		name: unitState.unitData.unitName ?? null,
		imoNumber: unitState.unitData.imoNumber ?? null,
		mmsiNumber: unitState.unitData.mmsiNumber ?? null,
		registrationNumber: unitState.unitData.registrationNumber ?? null,
		hullIdCoastGuard:
			(isUsCoastGuardHullId(unitState.unitData.hullIdStandard)
				? unitState.unitData.hullId
				: null) ?? null,
		isoHullId:
			(isIsoHullId(unitState.unitData.hullIdStandard)
				? unitState.unitData.hullId
				: null) ?? null,
		hullMachineId: unitState.unitData.machineId ?? null,
		callSign: unitState.unitData.callSign ?? null,
		yssPinCode: null, // not used in registration anymore
		overallLength: parseFloat(unitState.unitData.length),
		unitOfLength: unitState.unitData.lengthUnit,
		width: parseFloat(unitState.unitData.width),
		unitOfWidth: unitState.unitData.widthUnit,
		weightType: unitState.unitData.dimensionsType,
		...convertWeightToKilograms(weight, unitOfWeight),
	};
};
