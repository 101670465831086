import { Button, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ProductIndividualItemPurpose } from 'api';
import { InformationGuide } from 'assets/volvoIcons/Informational';
import { getPublicationProductIndividualUrl } from 'library/components/link';

export const PublicationsLink = ({
	serialNumber,
	isIcon = false,
}: Pick<ProductIndividualItemPurpose, 'serialNumber'> & {
	isIcon?: boolean;
}) => {
	const { t } = useTranslation(['home', 'productIndividual']);

	return (
		<Tooltip title={t('productIndividual:tooltip-publications-button')}>
			{isIcon ? (
				<IconButton
					component={Link}
					to={getPublicationProductIndividualUrl(serialNumber)}
					color="secondary"
					size="small"
					target="_blank">
					<InformationGuide />
				</IconButton>
			) : (
				<Button
					component={Link}
					startIcon={<InformationGuide />}
					to={getPublicationProductIndividualUrl(serialNumber)}
					target="_blank"
					variant="text">
					{t('home:subapps-publications')}
				</Button>
			)}
		</Tooltip>
	);
};
