import { PositionDto } from 'api';

export const getBlueprintPartForSales = (
	position: PositionDto | null | undefined
): PositionDto[] =>
	position ? getAllDataTypes(position, 'blueprintPartForSale') : [];

export const getMatchedInstallations = (
	position: PositionDto | null | undefined
): PositionDto[] =>
	position ? getAllDataTypes(position, 'matchedInstallation') : [];

export const getAllDataTypes = (
	position: PositionDto,
	dataFieldName: keyof PositionDto
): PositionDto[] => {
	const drivelines: PositionDto[] = [];

	const getInternal = (position: PositionDto) => {
		if (position[dataFieldName] != null) {
			drivelines.push(position);

			// bail out early, as  cannot contain another driveline
			return;
		}

		if (position.children == null) {
			return;
		}

		for (const positionChild of position.children) {
			getInternal(positionChild);
		}
	};

	getInternal(position);

	return drivelines;
};
