import { Paper } from '@mui/material';
import { PropsWithChildren } from 'react';

import { SxProps, Sxable } from 'library/types';

export const defaultSectionWrapperSx: SxProps = {
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	gap: 2,
	my: 2,
};

export const SectionWrapper = ({
	children,
	sx = defaultSectionWrapperSx,
}: PropsWithChildren & Sxable): JSX.Element => (
	<Paper component="section" variant="bottomBorder" sx={sx}>
		{children}
	</Paper>
);
