import { Box, Typography } from '@mui/material';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LinkProps } from 'react-router-dom';

import { UnitSearchResult } from 'api';
import { getUnitTitle } from 'api/responses/models/Unit/UnitStructure/Unit';
import { Info } from 'assets/volvoIcons/Informational/Info';
import { DialogIconButton } from 'library/components/controls/buttons/DialogIconButton';
import { joinTwoValuesWithCommaAndSpace } from 'library/formatters';
import { Sxable } from 'library/types';
import { textOverflowSx } from 'library/utils/themes';
import {
	UnitDetailView,
	UnitDetailViewData,
} from 'modules/MaintenancePlanner/Components/UnitDetailView';
import AvatarWithCampaignComponent from 'modules/Shared/Components/ConnectedUnits/AvatarWithCampaignComponent';
import { UnitOverviewLink } from 'modules/Shared/Components/ConnectedUnits/UnitOverviewLink';
import { isUnitIndustrial } from 'modules/Shared/State/unit';

export type UnitCellProps = Omit<
	UnitSearchResult,
	| 'ownerCompanyName'
	| 'ownerNameAndSurname'
	| 'ownerEmail'
	| 'isOwnerAnonymous'
	| 'installations'
> & { linkTarget?: LinkProps['target'] } & UnitDetailViewData &
	Sxable;

export const UnitCell = (props: UnitCellProps): JSX.Element | null => {
	const {
		id,
		name,
		brandOrType,
		model,
		profileImageId,
		outstandingCampaignType,
		queryAsExternal = false,
		linkTarget,
		sx = [],
	} = props;

	const unitTitle = getUnitTitle(name, brandOrType, model) || '\xa0';
	const { t } = useTranslation(['unitOverview']);

	const identifiers = getIdentifiers(props);

	return id > 0 ? (
		<Box
			sx={[
				{
					display: 'grid',
					gridTemplateColumns:
						'max-content minmax(50px, 1fr) max-content',
					alignSelf: 'stretch',
					alignItems: 'start',
					flex: 1,
					gap: 2,
					py: 1,
				},
				...(isArray(sx) ? sx : [sx]),
			]}>
			<UnitOverviewLink
				unitId={id}
				target={linkTarget}
				sx={{
					display: 'contents',

					':hover': {
						'& p': {
							textDecoration: 'underline',
						},
					},
				}}>
				<AvatarWithCampaignComponent
					imageId={profileImageId}
					campaignType={outstandingCampaignType}
					withZoom
				/>

				<Box sx={{ pt: 0.5 }}>
					<Typography
						title={unitTitle}
						variant="body2"
						sx={textOverflowSx}>
						{unitTitle}
					</Typography>

					<Typography
						title={identifiers}
						variant="body2"
						sx={textOverflowSx}>
						{identifiers}
					</Typography>
				</Box>
			</UnitOverviewLink>

			<DialogIconButton
				DialogComponent={UnitDetailView}
				data={{ id, queryAsExternal }}
				tooltipText={t('unitOverview:unit-detailed-button-tooltip')}
				Icon={<Info color="secondary" />}
			/>
		</Box>
	) : null;
};

const getIdentifiers = ({
	mainSegment,
	hullMachineId,
	hullId,
	imoNumber,
	mmsiNumber,
	callSign,
}: UnitCellProps) => {
	if (isUnitIndustrial(mainSegment)) {
		return hullMachineId ?? '';
	}

	return (
		[hullId, imoNumber, mmsiNumber, callSign].reduce(
			(prevValue, currValue) => {
				if (currValue != null) {
					return prevValue
						? joinTwoValuesWithCommaAndSpace(prevValue, currValue)
						: currValue;
				}
				return prevValue;
			},
			''
		) ?? ''
	);
};
