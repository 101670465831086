import { Info } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';

import { useDialogState } from 'library/hooks';

export interface DialogComponentProps<T = unknown> {
	data: T;
	handleClose: () => void;
}

interface DialogIconButtonProps<T> {
	data: T;
	DialogComponent: FC<DialogComponentProps<T>>;
	Icon?: React.ReactNode;
	tooltipText?: string;
}

export const DialogIconButton = <T,>({
	data,
	DialogComponent,
	tooltipText,
	Icon = <Info />,
}: DialogIconButtonProps<T>) => {
	const { open, handleOpen, handleClose } = useDialogState(false);

	return (
		<>
			<Tooltip
				disableHoverListener={!tooltipText}
				title={tooltipText}
				arrow>
				<IconButton onClick={handleOpen}>{Icon}</IconButton>
			</Tooltip>
			{open && <DialogComponent data={data} handleClose={handleClose} />}
		</>
	);
};
