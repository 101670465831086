import { PayloadAction } from '@reduxjs/toolkit';

import {
	FileData,
	FileUploadState,
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';
import {
	FileUploadAvatarStateHandlers,
	FileUploadStateHandlers,
} from 'modules/Shared/Components/Types/FileUpload';

import {
	selectGuidedRegistrationFiles,
	selectProfilePicturePath,
} from './Selectors';
import { resetGuidedRegistrationState } from './Thunks';

export interface guidedRegistrationFileUploadState extends FileUploadState {
	profilePicturePath: FileData | null;
}

const guidedRegistrationFileInitialState: guidedRegistrationFileUploadState = {
	...initialFileState,
	profilePicturePath: null,
};

const guidedRegistrationFilesSlice = createFileUploadSlice({
	name: 'guidedRegistrationFiles',
	initialState: guidedRegistrationFileInitialState,
	reducers: {
		newProfilePicture: (state, { payload }: PayloadAction<FileData>) => {
			// todo - when to delete item
			state.profilePicturePath = payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(
			resetGuidedRegistrationState.fulfilled,
			() => guidedRegistrationFileInitialState
		);
	},
});

export const {
	fileUploaded,
	fileDeleted,
	attachmentFolderCreated,
	newProfilePicture,
} = guidedRegistrationFilesSlice.actions;

export const fileHandler: FileUploadStateHandlers = {
	attachmentFolderCreated: attachmentFolderCreated,
	fileDeleted: fileDeleted,
	fileUploaded: fileUploaded,
	fileStateSelector: selectGuidedRegistrationFiles,
};

export const fileAvatarHandler: FileUploadAvatarStateHandlers = {
	...fileHandler,
	newProfilePicture,
	selectProfilePicturePath,
};

export default guidedRegistrationFilesSlice.reducer;
