import { GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PdfIcon } from 'assets/images/documents';
import { ButtonActionItem } from 'library/components/controls/datagrid/ButtonActionItem';
import { useDialogState } from 'library/hooks';
import { PrintProtocolDialog } from 'modules/ServiceProtocol/Components/ServiceProtocolPdf/PrintProtocolDialog';

export const ServiceProtocolPrintPdfActionItem = forwardRef<
	HTMLLIElement | null,
	Pick<GridActionsCellItemProps, 'showInMenu'> & {
		protocolId: number;
		hasActivityEvents: boolean;
	}
>(({ protocolId, hasActivityEvents }, ref): JSX.Element => {
	const { t } = useTranslation('commonButton');
	const { handleOpen, open, handleClose } = useDialogState();

	return (
		<>
			<ButtonActionItem
				ref={ref}
				key="print"
				onClick={handleOpen}
				startIcon={<PdfIcon />}
				showInMenu
				label={t('print')}
			/>

			{open && (
				<PrintProtocolDialog
					isOffline={!hasActivityEvents}
					isOpen
					onClose={handleClose}
					protocolId={protocolId}
				/>
			)}
		</>
	);
});
