import { Download } from '@mui/icons-material';
import { Box, LinearProgress, Link, Typography } from '@mui/material';
import { GridColDef, GridSlots, PropsFromSlot } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
	CampaignStatus,
	ConcernedIndividualCampaignDto,
	ConcernedIndividualStatus,
} from 'api';
import { campaignStatusArray } from 'domain/campaign';
import { concernedIndividualStatusArray } from 'domain/campaign/ConcernedIndividualStatus';
import { ParseKeys, TOptions } from 'i18next';
import { VolvoDataGrid } from 'library/components/controls';
import { getCorrectPcUrl } from 'library/components/link';
import { getLocalizedDateFormat, toPrecision } from 'library/formatters';
import { Sxable } from 'library/types';
import { EmptyDataGridColumnMenu } from 'modules/MaintenancePlanner/Components/EmptyDataGridColumnMenu';
import { ProductIndividualTitleLink } from 'modules/ProductIndividualview/Components';
import { CampaignWarning } from 'modules/Shared/Components/CampaignWarning';

interface CampaignsDataGridProps extends Sxable {
	columns: GridColDef<ConcernedIndividualCampaignDto>[];
	displayAsInnerGrid?: boolean;
	queryOptions: UseQueryOptions<ConcernedIndividualCampaignDto[] | null>;
	toolbar?: PropsFromSlot<GridSlots['toolbar']>;
	onDataLoaded?: (isEmpty: boolean) => void;
	initialState?: GridInitialStatePro;
}

export const CampaignsDataGrid = ({
	columns,
	displayAsInnerGrid = false,
	queryOptions,
	toolbar,
	initialState,
	onDataLoaded = noop,
	sx = [],
}: CampaignsDataGridProps): JSX.Element => {
	const { data, isLoading, isSuccess } = useQuery<
		ConcernedIndividualCampaignDto[] | null
	>(queryOptions);

	useEffect(() => {
		if (isSuccess) {
			onDataLoaded(!data?.length);
		}
	}, [data, isSuccess]);

	return (
		<VolvoDataGrid
			isInnerGrid={displayAsInnerGrid}
			columns={columns}
			rows={data}
			isLoading={isLoading}
			isRowSelectable={() => false}
			hideFooter={displayAsInnerGrid}
			slots={{
				columnMenu: EmptyDataGridColumnMenu,
				loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
				toolbar,
			}}
			slotProps={{
				loadingOverlay: {
					sx: {
						height: 3,
					},
				},
			}}
			disableColumnPinning={displayAsInnerGrid}
			sx={{ mx: 0, ...sx }}
			initialState={initialState}
		/>
	);
};

export type CampaignTranslationMappingType = Record<
	keyof Omit<
		ConcernedIndividualCampaignDto,
		'id' | 'type' | 'serviceBulletinId' | 'publicationLink'
	>,
	ParseKeys<
		['campaign', 'common', 'commonUnit', 'productIndividual'],
		TOptions,
		'campaign'
	>
>;

const defaultCampaignTranslationMapping: CampaignTranslationMappingType = {
	sccCode: 'campaign:campaign-name',
	title: 'campaign:title',
	serialNumber: 'common:serial-number',
	status: 'commonUnit:status',
	totalLeft: 'productIndividual:campaigns-total-left',
	validFrom: 'productIndividual:campaigns-valid-from',
	lastRepairDate: 'productIndividual:campaigns-last-repair-date',
	lastSubmissionDate: 'productIndividual:campaigns-last-submission-date',
	performedDate: 'productIndividual:campaigns-performed-date',
	campaignStatus: 'productIndividual:campaigns-campaign-status',
};

export type IndividualStatusFormatterType = (
	status: ConcernedIndividualStatus
) => ParseKeys<['productIndividual'], TOptions, 'productIndividual'>;

const defaultIndividualStatusFormatter: IndividualStatusFormatterType = (
	status
) => `productIndividual:concerned-individual-status-${status}`;

export const useCampaignColumns = (
	isLink: boolean = true,
	displayTypeColumn: boolean = true,
	headerTranslationMapping: CampaignTranslationMappingType = defaultCampaignTranslationMapping,
	getIndividualStatusTranslation: IndividualStatusFormatterType = defaultIndividualStatusFormatter
) => {
	const { t } = useTranslation([
		'productIndividual',
		'campaign',
		'common',
		'commonUnit',
	]);

	const columnsCampaign =
		useMemo((): GridColDef<ConcernedIndividualCampaignDto>[] => {
			const statusOptions = concernedIndividualStatusArray.map((id) => ({
				value: id,
				label: t(`productIndividual:concerned-individual-status-${id}`),
			}));

			const campaignStatusOptions = campaignStatusArray.map((id) => ({
				value: id,
				label: t(`campaign:status_${id}`),
			}));

			let columnConfiguration: GridColDef<ConcernedIndividualCampaignDto>[] =
				[];

			if (displayTypeColumn) {
				columnConfiguration = [
					{
						field: 'type',
						width: 30,
						renderHeader: () => null,
						renderCell: ({ row }) =>
							row.type ? (
								<CampaignWarning campaignType={row.type} />
							) : null,
						display: 'flex',
					},
				];
			}

			return [
				...columnConfiguration,
				{
					field: 'sccCode',
					headerName: t(headerTranslationMapping['sccCode']),
					minWidth: 120,
					flex: 2,
					renderCell: ({ row: { sccCode, publicationLink } }) => {
						const link = getCorrectPcUrl(publicationLink);

						return (
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									gap: 2,
									justifyContent: 'space-between',
								}}>
								<Typography variant="body2">
									{sccCode}
								</Typography>
								{link && (
									<Link href={link} target="_blank">
										<Download />
									</Link>
								)}
							</Box>
						);
					},
					display: 'flex',
				},
				{
					field: 'title',
					headerName: t(headerTranslationMapping['title']),
					minWidth: 320,
					flex: 5,
					renderCell: ({ row }) => (
						<Typography
							sx={{
								whiteSpace: 'pre-wrap',
							}}>
							{row.title}
						</Typography>
					),
					display: 'flex',
				},
				{
					field: 'serialNumber',
					headerName: t(headerTranslationMapping['serialNumber']),
					minWidth: 120,
					flex: 2,
					renderCell: ({ row: { serialNumber } }) => (
						<ProductIndividualTitleLink
							isActive={!!serialNumber && isLink}
							className="pc-link"
							target="_blank"
							serialNumber={serialNumber}
							sx={{
								textDecoration: 'underline',
								'&:hover': {
									textDecoration: 'underline',
								},
							}}
						/>
					),
					display: 'flex',
				},
				{
					field: 'status',
					type: 'singleSelect',
					valueOptions: statusOptions,
					headerName: t(headerTranslationMapping['status']),
					minWidth: 120,
					flex: 2,
					valueFormatter: (value) =>
						t(
							getIndividualStatusTranslation(
								value as ConcernedIndividualStatus
							)
						),
				},
				{
					field: 'totalLeft',
					headerName: t(headerTranslationMapping['totalLeft']),
					type: 'number',
					minWidth: 70,
					headerAlign: 'left',
					align: 'left',
					valueFormatter: (value) => toPrecision(value),
				},
				{
					field: 'validFrom',
					headerName: t(headerTranslationMapping['validFrom']),
					type: 'date',
					minWidth: 120,
					flex: 2,
					valueFormatter: (value) => getLocalizedDateFormat(value),
				},
				{
					field: 'lastRepairDate',
					headerName: t(headerTranslationMapping['lastRepairDate']),
					type: 'date',
					minWidth: 120,
					flex: 2,
					valueFormatter: (value) => getLocalizedDateFormat(value),
				},
				{
					field: 'lastSubmissionDate',
					headerName: t(
						headerTranslationMapping['lastSubmissionDate']
					),
					type: 'date',
					minWidth: 120,
					flex: 2,
					valueFormatter: (value) => getLocalizedDateFormat(value),
				},
				{
					field: 'performedDate',
					headerName: t(headerTranslationMapping['performedDate']),
					type: 'date',
					minWidth: 120,
					flex: 2,
					valueFormatter: (value) => getLocalizedDateFormat(value),
				},
				{
					field: 'campaignStatus',
					headerName: t(headerTranslationMapping['campaignStatus']),
					type: 'singleSelect',
					valueOptions: campaignStatusOptions,
					minWidth: 80,
					valueFormatter: (value) =>
						t(`campaign:status_${value as CampaignStatus}`),
				},
			];
		}, [t]);

	return columnsCampaign;
};
