import { ArrowForwardOutlined } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProductIndividualItemPurpose } from 'api';
import { AreaName } from 'domain/constants';
import { SectionButton } from 'library/components';
import { OperationType } from 'library/models/enums/OperationType';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';

export const RegisterProductButton = ({
	serialNumber,
	variant = 'outlined',
}: Pick<ProductIndividualItemPurpose, 'serialNumber'> &
	Pick<ButtonProps, 'variant'>) => {
	const { t } = useTranslation(['common', 'productIndividual']);
	const navigate = useNavigate();
	return (
		<AuthorizeComponent
			areaName={AreaName.Unit}
			permission={OperationType.Create}>
			<SectionButton
				text={t('common:register-category')}
				icon={<ArrowForwardOutlined />}
				onClick={() =>
					// TODO: create and use constant for url and use it also in getRegisterRoutes
					navigate(`/register/unit?serialNumber=${serialNumber}`)
				}
				variant={variant}
			/>
		</AuthorizeComponent>
	);
};
