import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import ShieldIcon from '@mui/icons-material/Shield';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import ManufacturedIcon from 'assets/volvoIcons/Status/ManufacturedIcon';
import { ParseKeys, TOptions } from 'i18next';

export type DrivelineId = {
	drivelineId: string;
};

export type InstallationId = {
	installationId: string;
};

export type ProductId = {
	id: number;
	serialNumber: string;
	/**
	 * @deprecated productId is old way, that was used before for passing serial number, currently you should use either id or serialNumber field
	 */
	productId: string;
};

export type InstallationDrivelineId = DrivelineId & InstallationId;

export type UnitStatus =
	| 'Manufactured'
	| 'DemoUseRegistered'
	| 'InternallyInvoiced'
	| 'Invoiced'
	| 'RegisteredUnit'
	| 'ConsumerRegistered'
	| 'ProductInOperation'
	| 'Commissioned';

export const unitLocationUpdateEventToTranslationMapping: {
	[key: string]: ParseKeys<['commonStatus'], TOptions, 'commonStatus'>;
} = {
	DemoRegistration: 'commonStatus:demo-registered',
	EndCustomerInteraction: 'commonStatus:customer-added',
	ProductInOperation: 'commonStatus:product-in-operation',
	Commissioning: 'commonStatus:commissioned',
};

export const unitStatusToTranslationMapping: {
	[key: string]: ParseKeys<['commonStatus'], TOptions, 'commonStatus'>;
} = {
	Manufactured: 'commonStatus:manufactured',
	DemoUseRegistered: 'commonStatus:demo-registered',
	Invoiced: 'commonStatus:invoiced',
	InternallyInvoiced: 'commonStatus:internally-invoiced',
	RegisteredUnit: 'commonStatus:unit-created',
	ConsumerRegistered: 'commonStatus:customer-added',
	ProductInOperation: 'commonStatus:product-in-operation',
	Commissioned: 'commonStatus:commissioned',
};

export const unitStatusToIconMapping: { [key: string]: React.ReactNode } = {
	Manufactured: <ManufacturedIcon />,
	DemoUseRegistered: <DirectionsBoatFilledIcon />,
	Invoiced: <ReceiptLongIcon />,
	InternallyInvoiced: <ReceiptLongIcon />,
	RegisteredUnit: <RemoveModeratorIcon />,
	ConsumerRegistered: <PersonIcon />,
	ProductInOperation: <VerifiedUserIcon />,
	Commissioned: <ShieldIcon />,
};
