import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { unitInfoQuery, unitStructureQuery } from 'api/queries';
import { Id } from 'domain/Id';
import { unitStatusToTranslationMapping } from 'domain/unit';
import {
	DRAWER_SIDE_SPACE,
	SectionWrapper,
	TextDisplay,
} from 'library/components';
import { getLocalizedDateFormat } from 'library/formatters';
import { CountryCityDisplay } from 'modules/UnitOverview/Components/CountryCityDisplay';
import { UnitIdentificationDisplay } from 'modules/UnitOverview/Components/UnitIdentificationDisplay';
import { LineOfBusinessDisplay } from 'modules/UnitOverview/UnitInformation/Components/LineOfBusinessDisplay';
import { UnitPurposeDisplay } from 'modules/UnitOverview/UnitInformation/Components/UnitPurposeDisplay';

import { UnitBaseInformation } from './UnitBaseInformation';

export const UnitInformation = ({ children, id }: Id & PropsWithChildren) => {
	const { t } = useTranslation(['commonUnit', 'commonStatus']);
	const { api } = useProductCenterApi();

	const { data } = useQuery(unitStructureQuery(id));
	const { data: unitInfo } = useQuery(unitInfoQuery(api, id));

	return !data ? (
		<></>
	) : (
		<>
			<UnitBaseInformation unit={data} imagesId={unitInfo?.imagesId} />
			<Box
				sx={{
					px: DRAWER_SIDE_SPACE,
					overflow: 'auto',
				}}>
				<SectionWrapper>
					<TextDisplay
						label={t('commonUnit:registration-number')}
						value={data.identification?.registrationNumber}
						sx={{ gridColumn: 'span 2' }}
					/>
					<UnitPurposeDisplay
						unitPurpose={data.consumerUsingPurpose}
					/>
					<LineOfBusinessDisplay
						mainSegment={data.mainSegment}
						lineOfBusiness={data.consumerLineOfBusiness}
					/>
				</SectionWrapper>
				<SectionWrapper>
					<UnitIdentificationDisplay
						mainSegment={data.mainSegment}
						unitIdentification={data.identification}
					/>
				</SectionWrapper>
				<SectionWrapper>
					<TextDisplay
						label={t('commonUnit:brand')}
						value={data.brandOrType}
					/>
					<TextDisplay
						label={t('commonUnit:model')}
						value={data.model}
					/>
				</SectionWrapper>
				<SectionWrapper>
					<CountryCityDisplay
						currentLocation={unitInfo?.currentLocation}
					/>
					<TextDisplay
						label={t('commonUnit:status')}
						value={
							data?.status &&
							t(unitStatusToTranslationMapping[data.status])
						}
						helperText={getLocalizedDateFormat(data?.statusDate)}
					/>
				</SectionWrapper>
				{children}
			</Box>
		</>
	);
};
