import { RootState } from 'store';

export const selectIsCompleted = (state: RootState) =>
	state.guidedRegistration.createdUnitId != null &&
	state.guidedRegistration.createdUnitId > 0;

export const selectSubmitErrors = (state: RootState) =>
	state.guidedRegistration.submitErrors;

export const selectActiveStep = (state: RootState): number =>
	state.guidedRegistration.activeStep;

export const selectProfilePicturePath = (state: RootState) =>
	state.guidedRegistrationFiles.profilePicturePath;

export const selectGuidedRegistrationUnitId = (state: RootState) =>
	state.guidedRegistration.createdUnitId;

export const selectGuidedRegistrationModel = (state: RootState) =>
	state.guidedRegistration.model;

export const selectUnitData = (state: RootState) =>
	state.guidedRegistration.unitData;

export const selectUnitMetadata = (state: RootState) =>
	state.guidedRegistration.unitMetadata;

export const selectMainSegment = (state: RootState) =>
	state.guidedRegistration.unitMetadata.mainSegment;

export const selectGuidedRegistrationFiles = (state: RootState) =>
	state.guidedRegistrationFiles;
