import { StyledLink } from './CustomLink';

export const EmailLink = ({
	email,
}: {
	email: string | null | undefined;
}): JSX.Element | null =>
	email ? (
		<StyledLink title={email} href={`mailto:${email}`} color="secondary">
			{email}
		</StyledLink>
	) : null;
