import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid-pro';

export const EmptyDataGridColumnMenu = (props: GridColumnMenuProps) => (
	<GridColumnMenu
		{...props}
		slots={{
			columnMenuColumnsItem: null,
		}}
	/>
);
