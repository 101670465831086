import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { DataType } from 'csstype';
import { noop } from 'lodash';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDoubleClick from 'use-double-click';

import { useWizard } from './useWizard';

export interface WizardButtonsProps {
	additionalButtons?: JSX.Element[];
	additionalButtonsPosition?: Extract<
		DataType.ContentPosition,
		'end' | 'start'
	>;
	backButtonAction?: () => void;
	isBackButtonDisabled?: boolean;
	isNextButtonDisabled?: boolean;
	isNextButtonSubmit?: boolean;
	nextButtonFormId?: string;
	nextButtonLabel?: string;
	onSubmit?: () => Promise<void> | void;
	loading?: boolean;
	onBack?: () => void;
}

export const WizardButtons = ({
	additionalButtons = [],
	additionalButtonsPosition = 'end',
	backButtonAction,
	nextButtonLabel,
	nextButtonFormId,
	isBackButtonDisabled = false,
	isNextButtonDisabled = false,
	isNextButtonSubmit = false,
	loading = false,
	onSubmit,
	onBack,
}: WizardButtonsProps): JSX.Element => {
	const { t } = useTranslation('commonButton');
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	const {
		hasPreviousStep,
		goToNextStep,
		gotoPreviousStep,
		skipStep,
		isCurrentStepOptional,
	} = useWizard();
	const buttonRef = useRef<HTMLButtonElement>(null);

	useDoubleClick({
		onSingleClick: isNextButtonSubmit ? noop : onSubmit || goToNextStep,
		onDoubleClick: noop,
		ref: buttonRef,
		latency: 200,
	});

	const handleBack = useCallback(() => {
		if (typeof onBack === 'function' ? onBack() : gotoPreviousStep()) {
			typeof backButtonAction === 'function' && backButtonAction();
		}
	}, []);

	const buttonSize = useMemo(
		() => (isMobile ? 'medium' : 'large'),
		[isMobile]
	);
	const allAdditionalButtons = useMemo(
		() =>
			isCurrentStepOptional
				? [
						<Button
							key="skip-button"
							onClick={skipStep}
							variant="outlined"
							color="secondary"
							size={buttonSize}>
							{t('skip')}
						</Button>,
						...additionalButtons,
					]
				: additionalButtons,
		[t, additionalButtons, buttonSize, isCurrentStepOptional, skipStep]
	);

	return (
		<Box>
			<Divider></Divider>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 2,
					pt: 2,
					justifyContent: 'space-between',
				}}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 2,
					}}>
					<Button
						variant="outlined"
						color="secondary"
						size={buttonSize}
						disabled={!hasPreviousStep || isBackButtonDisabled}
						onClick={handleBack}
						sx={{ mr: 'auto' }}>
						{t('back')}
					</Button>
					{additionalButtonsPosition === 'start' &&
						allAdditionalButtons}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 2,
					}}>
					{additionalButtonsPosition === 'end' &&
						allAdditionalButtons}
					<LoadingButton
						disabled={isNextButtonDisabled}
						ref={buttonRef}
						variant="contained"
						color="secondary"
						size={buttonSize}
						type={isNextButtonSubmit ? 'submit' : undefined}
						loading={loading}
						form={
							isNextButtonSubmit ? nextButtonFormId : undefined
						}>
						{nextButtonLabel || t('next')}
					</LoadingButton>
				</Box>
			</Box>
		</Box>
	);
};

export default memo(WizardButtons);
