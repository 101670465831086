import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { unitActiveServiceAgreementDataQuery } from 'api/queries';
import { serviceAgreementStatusToTranslationMapping } from 'api/responses/models/Unit/ServiceAgreementDto';
import { TextDisplay } from 'library/components';
import {
	formatValueWithSecondInBracket,
	getLocalizedDateFormat,
} from 'library/formatters';

export const ServiceAgreementDisplay = ({
	id,
}: {
	id: number;
}): JSX.Element => {
	const { t } = useTranslation(['unitOverview', 'common']);
	const { api } = useProductCenterApi();

	const { data, isLoading } = useQuery(
		unitActiveServiceAgreementDataQuery(api, id)
	);

	return data?.status ? (
		<>
			<TextDisplay
				label={t('unitOverview:service-agreement-title')}
				value={t(
					serviceAgreementStatusToTranslationMapping[data.status]
				)}
				sx={{
					gridColumn: 'span 2',
				}}
			/>
			<TextDisplay
				label={t('unitOverview:responsible-business-partner-label')}
				value={formatValueWithSecondInBracket(
					data?.businessPartnerName,
					data?.businessPartnerId
				)}
			/>
			<TextDisplay
				label={t('common:end-date')}
				value={getLocalizedDateFormat(data?.endDate)}
			/>
		</>
	) : (
		<TextDisplay
			label={t('unitOverview:service-agreement-title')}
			value={t('common:not-active-text')}
			loading={isLoading}
			sx={{
				'& .text-display__value': {
					color: 'grey.600',
				},
			}}
		/>
	);
};
