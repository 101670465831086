import { Paper, Typography } from '@mui/material';

import {
	getProfilePicture,
	getUnitTitle,
} from 'api/responses/models/Unit/UnitStructure/Unit';
import { t } from 'i18next';
import { DRAWER_SIDE_SPACE } from 'library/components';
import { UnitProps } from 'modules/Shared/State/unit';
import { UnitAvatar } from 'modules/UnitOverview/UnitInformation/Components/UnitAvatar';

import SegmentCellComponent from '../../ConnectedUnits/SegmentCellComponent';

interface UnitBaseInformationProps extends UnitProps {
	subtitle?: string;
	imagesId: number[] | undefined;
}
export const UnitBaseInformation = ({
	subtitle,
	imagesId,
	unit: { brandOrType, identification, mainSegment, model },
}: UnitBaseInformationProps) => {
	const profilePicture = getProfilePicture(imagesId);

	return (
		<>
			<UnitAvatar
				imageId={profilePicture}
				sx={
					profilePicture
						? {
								width: 'fit-content',
								height: 'auto',
							}
						: {
								m: DRAWER_SIDE_SPACE,
								mb: 0,
								width: (theme) => theme.spacing(16),
								height: (theme) => theme.spacing(9),
							}
				}
			/>
			<Paper
				component="section"
				variant="bottomBorder"
				sx={{
					mb: 0,
					mt: 2,
				}}>
				<Typography
					variant="h2"
					component="h1"
					sx={{
						px: DRAWER_SIDE_SPACE,
					}}>
					<Typography
						component="div"
						variant="body2"
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
						}}>
						<SegmentCellComponent
							segment={mainSegment}
							fontSize="medium"
						/>
						{mainSegment
							? t(
									`commonUnit:main-segment-${mainSegment}` as const
								)
							: ''}
					</Typography>
					{getUnitTitle(identification?.name, brandOrType, model) ||
						'\xa0'}
					{subtitle}
				</Typography>
			</Paper>
		</>
	);
};
