import { Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { productKeys } from 'api/queries';
import { AreaName } from 'domain/constants';
import { CustomSeverityDialog } from 'library/components';
import { useDialogState } from 'library/hooks';
import { useValidationErrorsSnackbar } from 'library/hooks/useValidationErrorsSnackbar';
import { OperationType } from 'library/models/enums/OperationType';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

export const AssignTemporaryDrivelineButton = ({
	serialNumber,
}: {
	serialNumber: string;
}) => {
	const { t } = useTranslation(['common', 'commonButton', 'searchProduct']);
	const { open, handleOpen, handleClose } = useDialogState();

	const { api } = useProductCenterApi();
	const queryClient = useQueryClient();

	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();

	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();

	const assignTemporaryChassisMutation = useMutation({
		mutationFn: (serialNumber: string) =>
			api.assignTemporaryChassis(serialNumber),
		onSuccess: () =>
			queryClient
				.invalidateQueries({
					queryKey: productKeys.search(),
				})
				.then(handleClose),
		onError: (error: Error) => {
			checkConsoleDebuggingReadAccess() && console.error(error);

			enqueueValidationSnackbar(error);
		},
	});

	const handleAccept = () => {
		assignTemporaryChassisMutation.mutate(serialNumber);
	};

	return (
		<>
			<AuthorizeComponent
				areaName={AreaName.AssignTempDriveline}
				permission={OperationType.Create}>
				<Button onClick={handleOpen} size="small">
					{t('searchProduct:button-assign-temporary')}
				</Button>
			</AuthorizeComponent>
			{open && (
				<CustomSeverityDialog
					headerText={t(
						'searchProduct:dialog-assign-temporary-driveline-title'
					)}
					content={t(
						'searchProduct:dialog-assign-temporary-driveline-content'
					)}
					acceptButtonLabel={t('commonButton:yes')}
					declineButtonLabel={t('commonButton:cancel')}
					isOpen={true}
					onAccept={handleAccept}
					onDecline={handleClose}
					loading={assignTemporaryChassisMutation.isPending}
				/>
			)}
		</>
	);
};
