import { Box, Button, CircularProgress } from '@mui/material';
import { noop } from 'lodash';
import {
	Dispatch,
	KeyboardEvent,
	MutableRefObject,
	ReactElement,
	RefObject,
	SetStateAction,
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import { keys } from 'library/keys';
import { Readonly } from 'library/types';

import { CustomInputHandle, Input, InputProps } from '../../input';

export interface CustomInputWithButtonHandle extends CustomInputHandle {
	resetError: () => void;
}

export interface InputWithButtonProps extends InputProps, Readonly {
	clearInputWhenSuccessful?: boolean;
	displayLoadingIndicatorDuringActionExecution?: boolean;
	displayLoadingIndicator?: boolean;
	icon: ReactElement;
	onButtonPressed: (input: string) => boolean | Promise<boolean>;
}

const getActionAsync = async (
	action: (input: string) => boolean | Promise<boolean>,
	param: string
) => (action instanceof Promise ? await action(param) : action(param));

const getIcon = (isLoading: boolean, icon: ReactElement) =>
	isLoading ? <CircularProgress size="2rem" color="inherit" /> : icon;

const getColor = (isError: boolean) => (isError ? 'error' : 'secondary');

const executeAction = async (
	inputRef: RefObject<CustomInputHandle>,
	wasInputChanged: MutableRefObject<boolean>,
	errorState: boolean,
	setErrorState: Dispatch<SetStateAction<boolean>>,
	onButtonPressed: InputWithButtonProps['onButtonPressed'],
	isLoading: boolean,
	clearInputWhenSuccessful: boolean
) => {
	if (!inputRef.current) {
		return;
	}
	const value = inputRef.current.getValue();

	if (errorState && !wasInputChanged.current) {
		onButtonPressed(value);
	}

	if (!(value && wasInputChanged.current && !isLoading)) {
		return;
	}

	wasInputChanged.current = false;

	const isSuccess = await getActionAsync(onButtonPressed, value);
	// inputRef might not exists, when async action finishes
	if (isSuccess && clearInputWhenSuccessful) {
		inputRef.current?.reset();
	} else if (!isSuccess) {
		setErrorState(true);
		inputRef.current?.focus();
	}
};

export const InputWithButton = forwardRef<
	CustomInputWithButtonHandle,
	InputWithButtonProps
>(
	(
		{
			clearInputWhenSuccessful = true,
			displayLoadingIndicatorDuringActionExecution = false,
			displayLoadingIndicator = false,
			isReadonly,
			icon,
			label,
			onButtonPressed,
			onChange = noop,
		},
		ref
	): JSX.Element => {
		const inputRef = useRef<CustomInputHandle>(null);
		const wasInputChanged = useRef(false);
		const [errorState, setErrorState] = useState(false);
		const [loading, setLoading] = useState(false);

		const isLoading =
			displayLoadingIndicatorDuringActionExecution && loading;

		useImperativeHandle(
			ref,
			() => ({
				focus: inputRef.current?.focus ?? noop,
				getValue: inputRef.current
					? inputRef.current.getValue
					: () => '',
				reset: inputRef.current?.reset ?? noop,
				resetError: () => {
					setErrorState(false);
					wasInputChanged.current = true;
				},
			}),
			[inputRef.current]
		);

		const handleButtonClicked = () => {
			setLoading(true);

			executeAction(
				inputRef,
				wasInputChanged,
				errorState,
				setErrorState,
				onButtonPressed,
				isLoading,
				clearInputWhenSuccessful
			).finally(() => setLoading(false));
		};

		const handleKeyPressed = (event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === keys.ENTER) {
				event.preventDefault();
				handleButtonClicked();
			}
		};

		const handleOnChange = (value: string) => {
			wasInputChanged.current = true;

			if (errorState) {
				setErrorState(false);
			}
			onChange(value);
		};

		return (
			<Box
				data-testid={`input-with-button-box${
					errorState ? '-error' : ''
				}`}
				className="input-with-button__root"
				sx={{
					display: 'flex',
					width: '100%',

					'&>.input-with-button__button': {
						minWidth: (theme) => theme.spacing(7),
						padding: '6px',
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						ml: -0.5,
						'&.Mui-disabled': {
							ml: 0,
						},
					},
				}}>
				<Input
					className={'input-with-button__input'}
					isError={errorState}
					isReadonly={isReadonly}
					label={label}
					InputProps={{
						type: 'search',
					}}
					onChange={handleOnChange}
					onKeyPress={handleKeyPressed}
					ref={inputRef}
					sx={{
						minWidth: 7,
						'&>.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
							{
								borderWidth: '1px',
							},
					}}
				/>
				<Button
					data-testid="input-with-button-icon"
					disabled={isReadonly}
					className="input-with-button__button"
					variant="contained"
					color={getColor(errorState)}
					onClick={handleButtonClicked}
					disableElevation>
					{getIcon(displayLoadingIndicator || isLoading, icon)}
				</Button>
			</Box>
		);
	}
);
