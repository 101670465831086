import { SvgIcon, SvgIconProps } from '@mui/material';

export const InformationGuide = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 17.9812L3 6.81092C3.65441 5.91251 4.83623 5.24609 7.24999 5.24609C9.66375 5.24609 10.8462 5.91279 11.5006 6.81123V17.9816C10.5644 17.2544 9.20808 16.8407 7.24999 16.8407C5.29198 16.8407 3.93601 17.2542 3 17.9812ZM12.5006 17.9814V6.81122C13.155 5.91271 14.3363 5.24609 16.75 5.24609C19.1637 5.24609 20.3455 5.91251 20.9999 6.81092V17.9812C20.0639 17.2542 18.708 16.8407 16.75 16.8407C14.792 16.8407 13.4364 17.2543 12.5006 17.9814ZM7.24999 4.24609C9.58896 4.24609 11.0693 4.83634 12.0006 5.84616C12.9315 4.83639 14.4109 4.24609 16.75 4.24609C19.4739 4.24609 21.0327 5.04633 21.9165 6.37785C21.9709 6.45981 21.9999 6.556 21.9999 6.65437V19.249C21.9999 19.4695 21.8555 19.664 21.6444 19.7277C21.4332 19.7914 21.2053 19.7092 21.0834 19.5255C20.4472 18.5671 19.27 17.8407 16.75 17.8407C14.2301 17.8407 13.0534 18.5673 12.4172 19.5258C12.3246 19.6654 12.1682 19.7493 12.0006 19.7493C11.8331 19.7493 11.6767 19.6654 11.584 19.5258C10.9479 18.5674 9.77 17.8407 7.24999 17.8407C4.72997 17.8407 3.55275 18.5671 2.91658 19.5255C2.79463 19.7092 2.56671 19.7914 2.35559 19.7277C2.14447 19.664 2 19.4695 2 19.249L2 6.65437C2 6.556 2.02902 6.45981 2.08342 6.37785C2.96725 5.04633 4.52602 4.24609 7.24999 4.24609ZM16.1485 9.60889V15.1461H17.5046V9.60889H16.1485ZM16.1372 8.85176H17.5159V7.30359H16.1372V8.85176Z"
		/>
	</SvgIcon>
);
