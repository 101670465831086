import { SvgIcon, SvgIconProps } from '@mui/material';

export const Info = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.00391 11.9986C3.00391 7.02989 7.03185 3.00195 12.0006 3.00195C16.9693 3.00195 20.9972 7.02989 20.9972 11.9986C20.9972 16.9673 16.9693 20.9953 12.0006 20.9953C7.03185 20.9953 3.00391 16.9673 3.00391 11.9986ZM12.0006 2.00195C6.47956 2.00195 2.00391 6.47761 2.00391 11.9986C2.00391 17.5196 6.47956 21.9953 12.0006 21.9953C17.5216 21.9953 21.9972 17.5196 21.9972 11.9986C21.9972 6.47761 17.5216 2.00195 12.0006 2.00195ZM11.0055 7.18849V6.24426C11.0055 6.13435 11.1002 5.99986 11.2507 5.99986L11.5735 6.00041C11.7487 6.00076 11.945 6.00116 12.0475 6.00116H12.7534C12.9039 6.00116 12.9987 6.13564 12.9987 6.24555V7.18849V7.75759C12.9987 7.86751 12.9039 8.00199 12.7534 8.00199H12.0475C11.945 8.00199 11.7487 8.00239 11.5735 8.00274L11.2507 8.00328C11.1002 8.00328 11.0055 7.8688 11.0055 7.75889V7.18849ZM11.0023 11.3607V10.281C11.0023 10.1711 11.097 10.0366 11.2476 10.0366L11.5703 10.0371L11.5715 10.0371C11.7465 10.0375 11.9421 10.0379 12.0443 10.0379H12.7503C12.9008 10.0379 12.9955 10.1723 12.9955 10.2823V11.3607V17.7464C12.9955 17.8563 12.9008 17.9908 12.7503 17.9908H12.0443C11.9422 17.9908 11.747 17.9912 11.5723 17.9915L11.5715 17.9915L11.5703 17.9915L11.2476 17.9921C11.097 17.9921 11.0023 17.8576 11.0023 17.7477V11.3607Z"
		/>
	</SvgIcon>
);
