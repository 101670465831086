import { Button, Tooltip } from '@mui/material';
import {
	GridRenderCellParams,
	GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { ServiceProtocolDto } from 'api';
import { CheckmarkCircle, Remove } from 'assets/volvoIcons/Control';
import { ServiceProtocolStatus } from 'domain/serviceProtocol/ServiceProtocolDto';
import {
	getLinkOpenInitiatedServiceProtocol,
	getLinkOpenServiceProtocolView,
} from 'library/components/link';

const ServiceProtocolStatusCellInternal = ({
	row,
	drivelineId,
}: GridRenderCellParams<
	ServiceProtocolDto,
	never,
	never,
	GridTreeNodeWithRender
> & { drivelineId: number }): JSX.Element => {
	const isInitiated = row.status === 'Initiated';

	const serviceProtocolPath = isInitiated
		? getLinkOpenInitiatedServiceProtocol(row.id, drivelineId)
		: getLinkOpenServiceProtocolView(row.id);

	return (
		<Button
			component={Link}
			target={'_self'}
			startIcon={<ServiceProtocolSymbol status={row.status} />}
			to={serviceProtocolPath}
		/>
	);
};

export const ServiceProtocolStatusCell = memo(
	ServiceProtocolStatusCellInternal
);

const ServiceProtocolSymbol = ({
	status,
}: {
	status: ServiceProtocolStatus;
}): JSX.Element | null => {
	switch (status) {
		case 'Cancelled':
			return (
				<Tooltip title={'Cancelled'}>
					<span>
						<Remove color="disabled" />
					</span>
				</Tooltip>
			);
		case 'Finalized':
			return (
				<Tooltip title={'Finalized'}>
					<span>
						<CheckmarkCircle color="success" />
					</span>
				</Tooltip>
			);
		case 'Initiated':
			return (
				<Tooltip title={'Initiated'}>
					<span>
						<CheckmarkCircle color="warning" />
					</span>
				</Tooltip>
			);
		default:
			return null;
	}
};
