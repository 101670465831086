import { AxiosResponse } from 'axios';

import { ApplicationApi } from 'api';

export const drivelineKeys = {
	all: ['driveline'] as const,

	id: (chassisSeries: string, chassisNumber: number) =>
		[drivelineKeys.all, chassisSeries, chassisNumber] as const,

	single: (id: number) => [...drivelineKeys.all, id] as const,

	structure: (id: number) =>
		[...drivelineKeys.single(id), 'driveline-structure'] as const,

	variants: (id: number) =>
		[...drivelineKeys.single(id), 'variants'] as const,

	serviceProtocols: (id: number) =>
		[...drivelineKeys.single(id), 'serviceProtocols'] as const,

	coverages: (id: number) =>
		[...drivelineKeys.single(id), 'coverages'] as const,

	campaigns: (
		id: number,
		includePerformed: boolean,
		includeClosed: boolean
	) =>
		[
			...drivelineKeys.single(id),
			'campaigns',
			includePerformed,
			includeClosed,
		] as const,
};

export const drivelineIdQuery = (
	api: ApplicationApi,
	chassisSeries: string,
	chassisNumber: number
) => ({
	queryKey: drivelineKeys.id(chassisSeries, chassisNumber),
	queryFn: async () =>
		(await api.getDrivelineId({ chassisSeries, chassisNumber })).data,
});

export const drivelineStructureQuery = (api: ApplicationApi, id: number) => ({
	queryKey: drivelineKeys.structure(id),
	queryFn: async () => (await api.getDrivelineStructureById(id)).data,
});

export const drivelineServiceProtocolQuery = (
	api: ApplicationApi,
	unitId: number
) => ({
	queryKey: drivelineKeys.serviceProtocols(unitId),
	queryFn: async () => (await api.getDrivelineServiceProtocols(unitId)).data,
});

export const drivelineCoveragesQuery = (api: ApplicationApi, id: number) => ({
	queryKey: drivelineKeys.coverages(id),
	queryFn: async () => (await api.getDrivelineCoverages(id)).data,
});

export const drivelineCampaignsQuery = (
	api: ApplicationApi,
	id: number,
	includePerformed: boolean,
	includeClosed: boolean,
	queryAsExternal: boolean
) => ({
	queryKey: drivelineKeys.campaigns(id, includePerformed, includeClosed),
	queryFn: async () => {
		const { data, status } = await api.getDrivelineCampaignsWithoutPolicy(
			id,
			{
				includePerformed,
				includeClosed,
				queryAsExternal,
			}
		);
		return status === 204 ? null : data;
	},
});

const drivelineBaseQuery =
	<TQueryKeyReturn, TReturn>(
		queryFn: (id: number) => TQueryKeyReturn,
		apiCall: (
			api: ApplicationApi,
			id: number
		) => Promise<AxiosResponse<TReturn>>
	) =>
	(api: ApplicationApi, id: number) => ({
		queryKey: queryFn(id),
		queryFn: async () => {
			const { data, status } = await apiCall(api, id);
			return status === 204 ? null : data;
		},
	});

export const drivelineVariantsQuery = drivelineBaseQuery(
	drivelineKeys.variants,
	(api, id) => api.getDrivelineVariants(id)
);
