import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { textOverflowSx } from 'library/utils/themes';
import { ProductIndividualTitleLink } from 'modules/ProductIndividualview/Components';

export type ProductIndividualCardSNFieldProps = {
	isLink?: boolean;
	serialNumber: string;
};

export const ProductIndividualCardSNField = ({
	isLink = true,
	serialNumber,
}: ProductIndividualCardSNFieldProps) => {
	const { t } = useTranslation(['common']);

	return (
		<Typography
			component="p"
			variant="subtitle2"
			sx={[
				{
					fontWeight: 400,
					...textOverflowSx,
				},
			]}>
			{`${t('common:serial-number')}: `}
			{isLink ? (
				<ProductIndividualTitleLink
					sx={{
						fontWeight: 'bold',
						textDecoration: 'none',
						'&:hover': { textDecoration: 'underline' },
					}}
					serialNumber={serialNumber}
					target="_blank"
				/>
			) : (
				serialNumber
			)}
		</Typography>
	);
};
