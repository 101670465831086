import { ParseKeys, TOptions } from 'i18next';

export const serviceAgreementStatusToTranslationMapping: {
	[key: string]: ParseKeys<['unitOverview'], TOptions, 'unitOverview'>;
} = {
	Active: 'unitOverview:service-agreement-active',
	Annulate: 'unitOverview:service-agreement-annulate',
	Expired: 'unitOverview:service-agreement-expired',
	Planned: 'unitOverview:service-agreement-planned',
};
