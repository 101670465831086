import { InputProps as MUIInputProps, TextField } from '@mui/material';
import { noop } from 'lodash';
import {
	ChangeEvent,
	KeyboardEventHandler,
	forwardRef,
	useImperativeHandle,
	useState,
} from 'react';

import { useFocus } from 'library/hooks/useFocus';
import { Readonly, Sxable } from 'library/types';

interface InternalInputProps {
	isError?: boolean;
}

export interface CustomInputHandle {
	focus: () => void;
	getValue: () => string;
	reset: () => void;
}

export interface InputProps extends Sxable, Readonly {
	className?: string;
	defaultValue?: string;
	InputProps?: MUIInputProps;
	label: string;
	onChange?: (value: string) => void | 'reject-change';
	onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
}

export const Input = forwardRef<
	CustomInputHandle,
	InternalInputProps & InputProps
>(
	(
		{
			isError = false,
			isReadonly,
			onChange = noop,
			onKeyPress = noop,
			sx,
			...restProps
		},
		ref
	): JSX.Element => {
		const [focusRef, setFocusRef] = useFocus();
		const [value, setValue] = useState('');

		useImperativeHandle(
			ref,
			() => ({
				focus: setFocusRef,
				getValue: () => value,
				reset: () => {
					setValue('');
				},
			}),
			[setFocusRef, value]
		);

		const handleChange = ({
			target: { value },
		}: ChangeEvent<HTMLInputElement>) => {
			onChange(value);
			setValue(value);
		};

		return (
			<TextField
				fullWidth
				error={isError}
				disabled={isReadonly}
				inputRef={focusRef}
				sx={{
					'&>.MuiInputBase-root': {
						backgroundColor: 'background.paper',
					},
					...sx,
				}}
				onChange={handleChange}
				onKeyPress={onKeyPress}
				value={value}
				{...restProps}
			/>
		);
	}
);
