import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShoppingCart = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.50977 4.51172C2.50977 4.23558 2.73362 4.01172 3.00977 4.01172H5.15185C5.89087 4.01172 6.51956 4.54994 6.63379 5.27969L7.0949 8.01172H20.0098C20.1556 8.01172 20.2943 8.07543 20.3892 8.18615C20.4842 8.29687 20.5261 8.44356 20.504 8.58775L19.7649 13.3919C19.6649 14.0413 19.3157 14.477 18.8199 14.725C18.3612 14.9544 17.8087 15.008 17.2939 15.008H8.27571L8.37234 15.5806L8.37355 15.5877L8.3735 15.5877C8.41102 15.8317 8.6209 16.0117 8.86768 16.0117H17.0098C17.2859 16.0117 17.5098 16.2356 17.5098 16.5117C17.5098 16.7879 17.2859 17.0117 17.0098 17.0117H8.86768C8.12866 17.0117 7.49998 16.4735 7.38574 15.7438L7.1928 14.6006C7.19171 14.5948 7.19073 14.5889 7.18984 14.5831L6.18076 8.60428C6.17968 8.59848 6.17869 8.59263 6.1778 8.58675L5.64719 5.44287L5.64598 5.4357L5.64603 5.43569C5.60851 5.19177 5.39863 5.01172 5.15185 5.01172H3.00977C2.73362 5.01172 2.50977 4.78786 2.50977 4.51172ZM7.26367 9.01172L8.10693 14.008H17.2939C17.7663 14.008 18.1271 13.9534 18.3725 13.8306C18.5808 13.7265 18.7263 13.566 18.7765 13.2398L19.427 9.01172H7.26367ZM9.50977 19.0117C9.23362 19.0117 9.00977 19.2356 9.00977 19.5117C9.00977 19.7879 9.23362 20.0117 9.50977 20.0117C9.78591 20.0117 10.0098 19.7879 10.0098 19.5117C10.0098 19.2356 9.78591 19.0117 9.50977 19.0117ZM8.00977 19.5117C8.00977 18.6833 8.68134 18.0117 9.50977 18.0117C10.3382 18.0117 11.0098 18.6833 11.0098 19.5117C11.0098 20.3401 10.3382 21.0117 9.50977 21.0117C8.68134 21.0117 8.00977 20.3401 8.00977 19.5117ZM15.5098 19.0117C15.2336 19.0117 15.0098 19.2356 15.0098 19.5117C15.0098 19.7879 15.2336 20.0117 15.5098 20.0117C15.7859 20.0117 16.0098 19.7879 16.0098 19.5117C16.0098 19.2356 15.7859 19.0117 15.5098 19.0117ZM14.0098 19.5117C14.0098 18.6833 14.6813 18.0117 15.5098 18.0117C16.3382 18.0117 17.0098 18.6833 17.0098 19.5117C17.0098 20.3401 16.3382 21.0117 15.5098 21.0117C14.6813 21.0117 14.0098 20.3401 14.0098 19.5117Z"
		/>
	</SvgIcon>
);
