import { ProductIndividualType } from 'api';
import { ProductIndividualSearchResultDto } from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { Namespace, ParseKeys, TFunction, TOptions } from 'i18next';

export interface ProductIndividualSearchResult
	extends ProductIndividualSearchResultDto {
	generatedUuid: string;
	evcConfigurationEmpty: boolean;
}

export const productIndividualStatusToTranslationMapping: {
	[key: string]: ParseKeys<['commonStatus'], TOptions, 'commonStatus'>;
} = {
	Null: 'commonStatus:unknown',
	Manufactured: 'commonStatus:manufactured',
	Invoiced: 'commonStatus:invoiced',
	InternallyInvoiced: 'commonStatus:internally-invoiced',
	RegisteredUnit: 'commonStatus:unit-created',
	ConsumerRegistered: 'commonStatus:customer-added',
	ProductInOperation: 'commonStatus:product-in-operation',
	Commissioned: 'commonStatus:commissioned',
	DemoUseRegistered: 'commonStatus:demo-registered',
	Replaced: 'commonStatus:replaced',
	Scrapped: 'commonStatus:scrapped',
};

export const productIndividualRatingToTranslationMapping: {
	[key: string]: ParseKeys<
		['productIndividual'],
		TOptions,
		'productIndividual'
	>;
} = {
	HeavyDutyCommercial: 'productIndividual:rating-hdc',
	MediumDutyCommercial: 'productIndividual:rating-mdc',
	LightDutyCommercial: 'productIndividual:rating-ldc',
	SpecialLightDutyCommercial: 'productIndividual:rating-sldc',
	LeisureDuty: 'productIndividual:rating-lc',
};

export const productIndividualPropulsorTypeToTranslationMapping: {
	[key: string]: ParseKeys<
		['productIndividual'],
		TOptions,
		'productIndividual'
	>;
} = {
	Duoprop: 'productIndividual:propulsor-type-duoprop',
	Singleprop: 'productIndividual:propulsor-type-singleprop',
	Waterjet: 'productIndividual:propulsor-type-waterjet',
};

// some translations could be missing
export const productIndividualTypeTranslation =
	(t: TFunction<Namespace>) =>
	(value: ProductIndividualType): string =>
		t([`productType:${value}`, 'commonStatus:unknown'], {
			defaultValue: 'Unknown', // fallback for the typescript
		});

export const pentaProductIndividualCategories = [
	'Unknown',
	'Engine',
	'Shield',
	'Drive',
	'ReverseGear',
	'Generator',
	'IndustrialComponent',
	'SteeringActuator',
	'ControlSystem',
	'Outboard',
	'ElectricMotor',
	'TractionBattery',
] as const;
