import { PayloadAction } from '@reduxjs/toolkit';

import {
	FileData,
	FileUploadState,
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';
import {
	FileUploadAvatarStateHandlers,
	FileUploadStateHandlers,
} from 'modules/Shared/Components/Types/FileUpload';

import { selectProfilePicturePath, selectUnitCreationFiles } from './Selectors';
import { resetUnitCreationState } from './Thunks';

export interface UnitCreationFileUploadState extends FileUploadState {
	profilePicturePath: FileData | null;
}

const unitCreationInitialState: UnitCreationFileUploadState = {
	...initialFileState,
	profilePicturePath: null,
};

const unitCreationFilesSlice = createFileUploadSlice({
	name: 'unitCreationFiles',
	initialState: unitCreationInitialState,
	reducers: {
		newProfilePicture: (state, { payload }: PayloadAction<FileData>) => {
			// todo - when to delete item
			state.profilePicturePath = payload;
		},
		// if you want to reset state please use resetState Thunk
	},
	extraReducers(builder) {
		builder.addCase(
			resetUnitCreationState.fulfilled,
			() => unitCreationInitialState
		);
	},
});

export const {
	fileUploaded,
	fileDeleted,
	attachmentFolderCreated,
	newProfilePicture,
} = unitCreationFilesSlice.actions;

export const fileHandler: FileUploadStateHandlers = {
	attachmentFolderCreated: attachmentFolderCreated,
	fileDeleted: fileDeleted,
	fileUploaded: fileUploaded,
	fileStateSelector: selectUnitCreationFiles,
};

export const fileAvatarHandler: FileUploadAvatarStateHandlers = {
	...fileHandler,
	newProfilePicture,
	selectProfilePicturePath,
};

export default unitCreationFilesSlice.reducer;
