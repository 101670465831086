import { Tooltip } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignType } from 'domain/campaign';
import {
	UnitAvatar,
	UnitAvatarProps,
} from 'modules/UnitOverview/UnitInformation/Components/UnitAvatar';

import { CampaignBadge } from './CampaignBadge';

export interface AvatarWithCampaignComponentProps {
	campaignType?: CampaignType | null;
}

const AvatarWithCampaignComponent = memo(
	({
		campaignType,
		imageId,
		withZoom,
	}: AvatarWithCampaignComponentProps & UnitAvatarProps): JSX.Element => {
		const { t } = useTranslation(['unitOverview']);
		return (
			<CampaignBadge campaignType={campaignType}>
				<Tooltip title={t('unitOverview:unit-avatar-tooltip')} arrow>
					<span>
						<UnitAvatar
							imageId={imageId}
							thumbnail
							withZoom={withZoom}
						/>
					</span>
				</Tooltip>
			</CampaignBadge>
		);
	}
);

export default AvatarWithCampaignComponent;
