import {
	GridColDef,
	GridRowParams,
	GridSlots,
	PropsFromSlot,
} from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceProtocolDto, useProductCenterApi } from 'api';
import { drivelineServiceProtocolQuery } from 'api/queries';
import { Remove, Trash } from 'assets/volvoIcons';
import {
	ServiceProtocolStatus,
	canUserCancelProtocol,
	canUserDeleteProtocol,
	serviceProtocolStatusToTranslationMapping,
} from 'domain/serviceProtocol';
import { VolvoDataGrid } from 'library/components/controls';
import { getLocalizedDateFormat, toPrecision } from 'library/formatters';
import { Sxable } from 'library/types';
import {
	CountryCell,
	getFilterOperators,
} from 'modules/EndCustomer/Shared/DataGrid/CellRender/CountryCell';

import { ServiceProtocolActionItem } from './ServiceProtocolActionItem';
import { ServiceProtocolDetailsLink } from './ServiceProtocolDetailsLink';
import { ServiceProtocolPrintPdfActionItem } from './ServiceProtocolPrintPdfActionItem';
import { ServiceProtocolStatusCell } from './ServiceProtocolStatusCell';

const dataGridInitialState: GridInitialStatePro = {
	sorting: {
		sortModel: [{ field: 'serviceDate', sort: 'desc' }],
	},
};

interface ServiceProtocolsDataGridProps extends Sxable {
	drivelineId: number;
	isInnerGrid?: boolean;
	toolbar?: PropsFromSlot<GridSlots['toolbar']>;
}

export const ServiceProtocolsDataGrid = ({
	drivelineId,
	isInnerGrid,
	sx,
	toolbar,
}: ServiceProtocolsDataGridProps): JSX.Element => {
	const { t } = useTranslation([
		'common',
		'commonUnit',
		'commonButton',
		'serviceProtocol',
	]);

	const queryClient = useQueryClient();
	const { api } = useProductCenterApi();
	const { data, isLoading } = useQuery(
		drivelineServiceProtocolQuery(api, drivelineId)
	);

	const columns = useMemo((): GridColDef<ServiceProtocolDto>[] => {
		const statusOptions: { value: ServiceProtocolStatus; label: string }[] =
			Object.keys(serviceProtocolStatusToTranslationMapping)
				.filter((val) => val !== 'Removed')
				.map((id) => ({
					value: id as ServiceProtocolStatus,
					label: t(
						serviceProtocolStatusToTranslationMapping[
							id as ServiceProtocolStatus
						]
					),
				}));

		return [
			{
				field: 'status',
				type: 'singleSelect',
				valueOptions: statusOptions,
				headerName: t('commonUnit:status'),
				headerAlign: 'left',
				width: 60,
				renderCell: (params) => (
					<ServiceProtocolStatusCell
						{...params}
						drivelineId={drivelineId}
					/>
				),
				display: 'flex',
			},
			{
				field: 'serviceDate',
				type: 'date',
				headerName: t('serviceProtocol:service-date'),
				headerAlign: 'left',
				minWidth: 130,
				flex: 1,
				valueFormatter: (value) => getLocalizedDateFormat(value),
			},
			{
				field: 'generatedServiceEvent',
				headerName: t('serviceProtocol:generated-service-event'),
				headerAlign: 'left',
				minWidth: 300,
				flex: 3,
			},
			{
				field: 'currentEngineHours',
				type: 'number',
				align: 'left',
				headerName: t('commonUnit:operating-hours'),
				headerAlign: 'left',
				minWidth: 150,
				flex: 1,
				valueFormatter: (value) => toPrecision(value),
			},
			{
				field: 'servicePerformedBy',
				headerName: t('serviceProtocol:performed-by'),
				headerAlign: 'left',
				minWidth: 150,
				flex: 2,
			},
			{
				field: 'country',
				headerName: t('common:country'),
				flex: 1,
				minWidth: 130,
				type: 'singleSelect',
				renderCell: ({ row }) => (
					<CountryCell countryCode={row.location.country} />
				),
				filterOperators: getFilterOperators,
				align: 'left',
				display: 'flex',
			},
			{
				field: 'city',
				headerName: t('common:city'),
				headerAlign: 'left',
				minWidth: 130,
				flex: 1,
				valueGetter: (_, { location }) => location.city,
			},
			{
				field: 'actions',
				type: 'actions',
				align: 'right',
				minWidth: 160,
				cellClassName: 'service-protocol-grid__actions',
				getActions: ({ row }: GridRowParams<ServiceProtocolDto>) => {
					const additionalButtons = [
						<ServiceProtocolDetailsLink
							drivelineId={drivelineId}
							key="details"
							id={row.id}
							status={row.status}
						/>,
					];

					if (canUserDeleteProtocol(row, queryClient)) {
						additionalButtons.push(
							<ServiceProtocolActionItem
								key="delete"
								icon={<Trash />}
								showInMenu
								serviceProtocolId={row.id}
								drivelineId={drivelineId}
								apiAction={(id) =>
									api.deleteServiceProtocol(id)
								}
								confirmationDialogTitle={'commonButton:delete'}
								confirmationDialogContent={
									'serviceProtocol:confirmation-remove-initiated-protocol'
								}
								successfulMessage={
									'serviceProtocol:remove-initiated-protocol-successful-message'
								}
							/>
						);
					}

					if (canUserCancelProtocol(row, queryClient)) {
						additionalButtons.push(
							<ServiceProtocolActionItem
								key="cancel"
								icon={<Remove />}
								showInMenu
								serviceProtocolId={row.id}
								drivelineId={drivelineId}
								apiAction={(id) =>
									api.cancelServiceProtocol(id)
								}
								confirmationDialogTitle={'commonButton:cancel'}
								confirmationDialogContent={
									'serviceProtocol:confirmation-cancel-selected-protocol'
								}
								successfulMessage={
									'serviceProtocol:cancel-finalized-protocol-successful-message'
								}
							/>
						);
					}

					additionalButtons.push(
						<ServiceProtocolPrintPdfActionItem
							key="print"
							protocolId={row.id}
							hasActivityEvents={row.hasActivityEvents}
							showInMenu
						/>
					);

					return additionalButtons;
				},
			},
		];
	}, [t, drivelineId, queryClient, api]);

	return (
		<VolvoDataGrid
			isLoading={isLoading}
			isInnerGrid={isInnerGrid}
			rows={data}
			columns={columns}
			initialState={dataGridInitialState}
			slots={{
				toolbar: toolbar,
			}}
			sx={{
				'.service-protocol-grid__actions svg': {
					fontSize: '1.5rem',
				},
				...sx,
			}}
		/>
	);
};
