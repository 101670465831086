import { Menu as MenuIcon } from '@mui/icons-material';
import {
	Box,
	Chip,
	Divider,
	ListItemText,
	Menu,
	MenuItem,
	Theme,
	Typography,
	useMediaQuery,
} from '@mui/material/';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Namespace } from 'i18next';
import { useContextMenuState } from 'library/hooks';

import { TabConfiguration } from './types';

type VisibleTab = Pick<TabConfiguration, 'path' | 'subtitle' | 'badge'> & {
	title: string;
};

export interface VerticalTabsProps {
	visibleTabs: VisibleTab[];
	activeTab: TabConfiguration | undefined;
}

export const SECTION_TITLE_BUTTONS_CONTAINER_ID =
	'SectionTitleButtonsContainerId';

export const VerticalTabs = memo(
	({ activeTab, visibleTabs }: VerticalTabsProps) => {
		const { t } = useTranslation<Namespace>();

		const isMobile = useMediaQuery((theme: Theme) =>
			theme.breakpoints.only('xs')
		);

		const sortedTabs = [...visibleTabs].sort((a, b) =>
			a.title.localeCompare(b.title)
		);

		const { open, onClose, onOpen, anchorElement } = useContextMenuState();

		const withDivider = activeTab?.hasDivider ?? true;

		return (
			<>
				{isMobile && <Divider sx={{ mt: 4, mb: 2 }} />}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: { xs: 'wrap', sm: 'unset' },
						minHeight: 40,
						gap: 2,
						mb: withDivider ? 0 : 2,
					}}>
					<Typography
						id="vertical-section-button"
						variant="h3"
						onClick={onOpen}
						aria-controls={
							open ? 'vertical-section-menu' : undefined
						}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						sx={{
							marginRight: 'auto',
							cursor: 'pointer',
							'&:hover': {
								textDecoration: 'underline',
							},
							'& > svg': {
								mr: 1,
								py: 0.25,
								verticalAlign: 'bottom',
							},
						}}>
						{isMobile ? <MenuIcon /> : undefined}
						{t(
							activeTab?.pageTitle ??
								activeTab?.title ??
								'commonButton:select',
							{
								defaultValue: 'commonButton:select',
							}
						)}
					</Typography>
					<Box
						id={SECTION_TITLE_BUTTONS_CONTAINER_ID}
						key={activeTab?.path ?? 'notSelected'} // will force cleanup, when tab is changed
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
							zIndex: 1,
							ml: 'auto',
						}}></Box>
				</Box>
				{withDivider && <Divider sx={{ my: 2 }} />}
				<Menu
					id="vertical-section-menu"
					aria-labelledby="vertical-section-button"
					anchorEl={anchorElement}
					open={open}
					onClose={onClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					slotProps={{
						paper: {
							style: {
								width: 300,
							},
						},
					}}>
					{sortedTabs.map(({ path, title, subtitle, badge }) => (
						<MenuItem
							key={title}
							sx={{
								padding: 0,
								'& > a': {
									flex: '1 1 auto',
									display: 'flex',
									alignItems: 'center',
									padding: (theme) => theme.spacing(0.75, 2),
									textDecoration: 'none',
									':hover': {
										textDecoration: 'underline',
									},
									color: 'primary.main',
								},
							}}>
							<Link to={path} onClick={onClose}>
								<ListItemText>
									{title}
									{badge && (
										<Chip
											label={badge}
											variant="filled"
											color="primary"
											size="small"
											sx={{ marginLeft: 5 }}
										/>
									)}
								</ListItemText>

								{subtitle && (
									<Typography
										variant="body2"
										sx={{
											color: 'grey.500',
											paddingX: 2,
											lineHeight: 'normal',
											flex: '1 1 auto',
										}}>
										{subtitle}
									</Typography>
								)}
							</Link>
						</MenuItem>
					))}
				</Menu>
			</>
		);
	}
);
