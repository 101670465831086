import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, debounce } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SearchProductTextField } from './SearchProductTextField';

export const searchParamName = 'by';

type SearchFormValues = {
	phrase: string;
};

export const SearchProductForm = () => {
	const { t } = useTranslation(['searchProduct']);

	const [searchParams, setSearchParams] = useSearchParams();
	const inputValue = searchParams.get(searchParamName);

	const { register, handleSubmit, watch, setValue } =
		useForm<SearchFormValues>({
			mode: 'onChange',
			defaultValues: {
				phrase: inputValue || '',
			},
		});

	const submitHandler: SubmitHandler<SearchFormValues> = (data) => {
		setSearchParams((prev) => {
			if (
				data.phrase.length > 2 &&
				prev.get(searchParamName) !== data.phrase
			) {
				prev.set(searchParamName, data.phrase);
			}

			return prev;
		});
	};

	const handleClear = () => {
		setValue('phrase', '');
		setSearchParams((params) => {
			params.set(searchParamName, '');
			return params;
		});
	};

	const searchValue = watch('phrase');

	const debouncedSubmit = debounce(handleSubmit(submitHandler), 1500);

	return (
		<form
			onSubmit={handleSubmit(submitHandler)}
			onReset={handleClear}
			onChange={debouncedSubmit}>
			<SearchProductTextField
				id="search-product-input"
				label={t('search-input-helper-text')}
				variant="standard"
				slotProps={{
					inputLabel:
						searchValue.length == 0 ? { shrink: false } : undefined,
					input: {
						startAdornment: (
							<InputAdornment position="start">
								<Search color="primary" />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton type="reset">
									<Close color="primary" />
								</IconButton>
							</InputAdornment>
						),
					},
				}}
				{...register('phrase')}
				sx={{
					width: { xs: 1, sm: 550 },
				}}
			/>
		</form>
	);
};
