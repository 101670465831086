import {
	Color,
	PaletteColor,
	PaletteColorOptions,
	Theme,
	createTheme,
	keyframes,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { CSSProperties } from 'react';

import Variables from './Variables';

declare module '@mui/material/styles' {
	interface Palette {
		gray: PaletteColor;
		black: PaletteColor;
		flow: Color;
		teal: Color;
	}
	interface PaletteOptions {
		gray: PaletteColorOptions;
		black: PaletteColorOptions;
		flow?: ColorPartial;
		teal?: ColorPartial;
		leaf?: ColorPartial;
	}
}

declare module '@mui/material/styles/createPalette' {
	interface TypeBackground {
		blue: string;
		variant: string;
	}
}

declare module '@mui/material/Paper' {
	interface PaperPropsVariantOverrides {
		flatGrey: true;
		bottomBorder: true;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		text: true;
		gray: true;
		black: true;
	}
}

declare module '@mui/material/Badge' {
	interface BadgePropsColorOverrides {
		gray: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0, // small
			sm: 600, // mobile
			md: 1240, // tablet
			lg: 1920, // laptop
			xl: 1920, // desktop
		},
	},
	typography: {
		fontFamily: [
			'Volvo Novum',
			'Noto Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h1: {
			fontSize: '3rem',
			fontWeight: '700',
			lineHeight: '3.5rem',
			letterSpacing: '-0.48px',
			'@media (max-width: 904px)': {
				fontSize: '2.375rem',
				lineHeight: '2.875rem',
				letterSpacing: '-0.38px',
			},
		},
		h2: {
			fontSize: '2rem',
			fontWeight: '700',
			lineHeight: '2.5rem',
			letterSpacing: '-0.32px',
			'@media (max-width: 904px)': {
				fontSize: '1.875rem',
				lineHeight: '2.25rem',
				letterSpacing: '-0.3px',
			},
		},
		h3: {
			fontSize: '1.5rem',
			fontWeight: '700',
			lineHeight: '2rem',
			letterSpacing: '-0.24px',
			'@media (max-width: 904px)': {
				fontSize: '1.375rem',
				lineHeight: '1.75rem',
				letterSpacing: '-0.22px',
			},
		},
		h4: {
			fontSize: '1.25rem',
			fontWeight: '700',
			lineHeight: '1.625rem',
			letterSpacing: '-0.20px',
		},
		h5: {
			fontSize: '1.25rem',
			fontWeight: '700',
			lineHeight: '1.625rem',
			letterSpacing: '-0.20px',
		},
		h6: {
			fontSize: '1.25rem',
			fontWeight: '700',
			lineHeight: '1.625rem',
			letterSpacing: '-0.20px',
		},
		subtitle1: {
			fontSize: '1rem',
			fontWeight: '700',
			lineHeight: '1.5rem',
			letterSpacing: '0',
		},
		subtitle2: {
			fontSize: '0.875rem',
			fontWeight: '700',
			lineHeight: '1.25rem',
			letterSpacing: '0',
		},
		body1: {
			fontSize: '1rem',
			fontWeight: '400',
			lineHeight: '1.5rem',
			letterSpacing: '0',
		},
		body2: {
			fontSize: '0.875rem',
			fontWeight: '400',
			lineHeight: '1.25rem',
			letterSpacing: '0',
		},
		button: {
			fontSize: '0.875rem',
			fontWeight: '400',
			lineHeight: '1.25rem',
			letterSpacing: '0.28px',
		},
		overline: {
			fontSize: '0.875rem',
			fontWeight: '400',
			lineHeight: '1.25rem',
			letterSpacing: '0.28px',
		},
		caption: {
			fontSize: '0.75rem',
			fontWeight: '400',
			lineHeight: '1rem',
			letterSpacing: '0.24px',
		},
	},
	palette: {
		mode: 'light',
		primary: {
			main: '#141414',
			light: '#5b5b5b',
			dark: '#232323',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#004FBC',
			light: '#2B8EDE',
			dark: '#104E9B',
			contrastText: '#ffffff',
		},
		background: {
			default: '#f7f7f7',
			paper: '#ffffff',
			variant: '#EDEDED',
			blue: 'rgba(0, 79, 188, 0.08)',
		},
		gray: {
			main: '#F7F7F7',
			light: '#ffffff',
			dark: '#c5c5c5',
			contrastText: '#000000',
		},
		text: {
			primary: 'rgba(0,0,0,0.87)',
			secondary: 'rgba(0,0,0,0.6)',
			disabled: 'rgba(0,0,0,0.38)',
		},
		error: {
			main: '#bf2012',
			light: '#cb4c41',
			dark: '#85160c',
			contrastText: '#ffffff',
		},
		warning: {
			main: '#ffa000',
			light: '#ffb333',
			dark: '#b27000',
			contrastText: 'rgba(0,0,0,0.87)',
		},
		info: {
			main: '#2b8ede',
			light: '#55a4e4',
			dark: '#1e639b',
			contrastText: '#ffffff',
		},
		success: {
			main: '#1A6C31',
			light: '#2db954',
			dark: '#1A6C31',
			contrastText: '#ffffff',
		},
		black: {
			main: '#000000',
			light: '#393939',
			dark: '#000000',
			contrastText: '#ffffff',
		},
		divider: 'rgba(0,0,0,0.12)',
		grey: {
			50: '#F7F7F7',
			100: '#EDEDED',
			200: '#E1E1E1',
			300: '#D0D0D0',
			400: '#A9A9A9',
			500: '#8D8D8D',
			600: '#6E6E6E',
			700: '#575757',
			800: '#424242',
			900: '#323232',
			A100: '#969696',
			A200: '#B8B8B8',
			A400: '#EFEFEF',
		},
		flow: {
			100: '#C3D2D6',
			200: '#96B0B6',
			300: '#678C96',
			400: '#396976',
		},
		teal: {
			100: '#B8DED8',
			200: '#8DC9BF',
			300: '#66B3A6',
			400: '#50A294',
		},
		leaf: {
			100: '#C8E691',
			200: '#A8D46B',
			300: '#8FC54E',
			400: '#78B833',
		},
	},
});

export const ADD_ROW_ANIMATION_CLASS_NAME = 'MuiDataGrid-row--add-animation';
export const ADD_ROW_DISABLED_CLASS_NAME = 'MuiDataGrid-row--disabled';

const getAnimation = ({
	palette: {
		grey: { A100, A200 },
	},
}: Theme): CSSProperties =>
	({
		animation: `${keyframes(
			`from { border-color: ${A100}; background: ${A200}; } to { border-color: transparent; }`
		)} 2s linear 1`,
	}) as const;

export const textOverflowSx = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
} as const;

export const PCTheme = createTheme(
	theme,
	{
		components: {
			MuiDataGrid: {
				styleOverrides: {
					root: {
						border: 'none',
						'& .MuiDataGrid-columnHeaders': {
							borderTop: '1px solid ' + theme.palette.grey[300],
							borderTopLeftRadius: 'unset',
							borderTopRightRadius: 'unset',
						},
						'& .MuiDataGrid-columnHeader': {
							backgroundColor: 'white',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontWeight: 'unset',
						},
						'& .MuiDataGrid-columnHeader--pinnedLeft': {
							backgroundColor: theme.palette.grey[50],
						},
						'& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
							{
								outline: 'none',
							},
						'& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
							{
								display: 'none',
							},
						[`& .${ADD_ROW_ANIMATION_CLASS_NAME}`]:
							getAnimation(theme),
						[`& .${ADD_ROW_DISABLED_CLASS_NAME}`]: {
							color: theme.palette.grey[200],
							'&:hover, &.Mui-hovered': {
								backgroundColor: 'transparent',
							},
						},
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						boxShadow: 'none',
						textAlign: 'center',
					},
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						whiteSpace: 'pre-wrap',
					},
				},
			},
			MuiButtonGroup: {
				styleOverrides: {
					root: {
						boxShadow: 'none',
					},
				},
			},
			MuiStepConnector: {
				styleOverrides: {
					root: {
						'&': {
							top: theme.spacing(2),
						},
					},
				},
			},
			MuiStepIcon: {
				styleOverrides: {
					root: {
						'&': {
							fontSize: '2rem',
						},
						'&.Mui-completed': {
							color: theme.palette.secondary.main,
						},
						'&.Mui-active': {
							color: theme.palette.secondary.main,
						},
						'&.Mui-error': {
							color: theme.palette.error.main,
						},
					},
					completed: {},
					active: {},
				},
			},
			MuiPaper: {
				variants: [
					{
						props: { variant: 'flatGrey' },
						style: {
							padding: theme.spacing(2),
							boxShadow: 'none',
							backgroundColor: theme.palette.background.default,
						},
					},
					{
						props: { variant: 'bottomBorder' },
						style: {
							boxShadow: 'none',
							backgroundColor: theme.palette.background.paper,
							borderBottom: '1px solid',
							borderColor: theme.palette.grey[100],
							borderRadius: 0,
							paddingBottom: theme.spacing(2),
							marginBottom: theme.spacing(1),
						},
					},
				],
			},
			MuiDialogActions: {
				styleOverrides: {
					root: { padding: theme.spacing(2, 3) },
				},
			},
		},
	},
	{
		custom: {
			bodyContainerPaddingY: '1em',
			headerHeight: theme.spacing(10),
			navigationHeight: theme.spacing(7),
			footerHeight: theme.spacing(7),
			dataGridHeight: theme.spacing(90),
			getAnimation,
		},
	} as Variables
);
