 
import { SvgIconProps, Tooltip } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import IndustrialIcon from 'assets/volvoIcons/Segments/IndustrialIcon';
import MarineIcon from 'assets/volvoIcons/Segments/MarineIcon';
import { ParseKeys, TOptions } from 'i18next';
import { MainSegment } from 'modules/Shared/State/unit';

export const unitSegmentToTranslationMapping: {
	[key: string]: ParseKeys<['commonUnit'], TOptions, 'commonUnit'>;
} = {
	M: 'commonUnit:main-segment-M',
	I: 'commonUnit:main-segment-I',
};

interface IProps {
	segment: MainSegment | null;
	fontSize?: SvgIconProps['fontSize'];
}

const SegmentCellComponent = ({ segment, fontSize }: IProps): JSX.Element => {
	const { t } = useTranslation(['commonUnit']);
	const iconSize = fontSize ? fontSize : 'large';

	switch (segment) {
		case 'M':
			return (
				<Tooltip
					title={t(unitSegmentToTranslationMapping[segment])}
					arrow>
					<span>
						<MarineIcon fontSize={iconSize} />
					</span>
				</Tooltip>
			);
		case 'I':
			return (
				<Tooltip
					title={t(unitSegmentToTranslationMapping[segment])}
					arrow>
					<span>
						<IndustrialIcon fontSize={iconSize} />
					</span>
				</Tooltip>
			);
		default:
			return <></>;
	}
};

export default memo(SegmentCellComponent);
