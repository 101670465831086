import { SvgIcon, SvgIconProps } from '@mui/material';

const IndustrialIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 264 264" {...props}>
		<path
			d="M112.743 192.095C112.743 195.067 111.862 197.973 110.21 200.444C108.559 202.916 106.212 204.842 103.466 205.979C100.72 207.117 97.6979 207.414 94.7826 206.835C91.8674 206.255 89.1896 204.823 87.0878 202.722C84.986 200.62 83.5547 197.942 82.9748 195.027C82.3949 192.112 82.6926 189.09 83.83 186.344C84.9675 183.598 86.8937 181.251 89.3651 179.6C91.8366 177.948 94.7422 177.067 97.7146 177.067C101.7 177.067 105.523 178.65 108.341 181.469C111.16 184.287 112.743 188.109 112.743 192.095V192.095Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M44.6156 192.095C44.6156 195.067 43.7342 197.973 42.0829 200.444C40.4315 202.916 38.0844 204.842 35.3383 205.979C32.5922 207.117 29.5704 207.414 26.6552 206.835C23.7399 206.255 21.0621 204.823 18.9603 202.722C16.8585 200.62 15.4273 197.942 14.8474 195.027C14.2675 192.112 14.5651 189.09 15.7025 186.344C16.84 183.598 18.7663 181.251 21.2377 179.6C23.7091 177.948 26.6147 177.067 29.5871 177.067C33.5729 177.067 37.3955 178.65 40.2139 181.469C43.0323 184.287 44.6156 188.109 44.6156 192.095V192.095Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M180.878 192.095C180.878 195.067 179.997 197.973 178.346 200.444C176.694 202.916 174.347 204.842 171.601 205.979C168.855 207.117 165.833 207.414 162.918 206.835C160.003 206.255 157.325 204.823 155.223 202.722C153.121 200.62 151.69 197.942 151.11 195.027C150.53 192.112 150.828 189.09 151.965 186.344C153.103 183.598 155.029 181.251 157.5 179.6C159.972 177.948 162.877 177.067 165.85 177.067C169.836 177.067 173.658 178.65 176.477 181.469C179.295 184.287 180.878 188.109 180.878 192.095Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M249.002 192.095C249.002 195.067 248.12 197.973 246.469 200.444C244.818 202.916 242.471 204.842 239.725 205.979C236.978 207.117 233.957 207.414 231.041 206.835C228.126 206.255 225.448 204.823 223.347 202.722C221.245 200.62 219.813 197.942 219.234 195.027C218.654 192.112 218.951 189.09 220.089 186.344C221.226 183.598 223.152 181.251 225.624 179.6C228.095 177.948 231.001 177.067 233.973 177.067C237.959 177.067 241.782 178.65 244.6 181.469C247.418 184.287 249.002 188.109 249.002 192.095Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M29.5825 177.067H230.961"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M32.5942 207.126H230.975"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M158.828 131.983H113.742V177.068H158.828V131.983Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M221.959 131.983H176.873V177.068H221.959V131.983Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M128.023 116.953V101.922"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M125.406 101.609L49.4813 79.8971C48.0794 79.4943 46.7731 78.8129 45.6406 77.8936C44.5081 76.9743 43.5727 75.8361 42.8903 74.547C42.2078 73.2579 41.7924 71.8444 41.6689 70.391C41.5453 68.9376 41.7162 67.4742 42.1712 66.0884C42.6262 64.7026 43.3561 63.4229 44.3172 62.3257C45.2782 61.2284 46.4507 60.3363 47.7645 59.7026C49.0783 59.0689 50.5064 58.7067 51.9634 58.6376C53.4204 58.5686 54.8763 58.7941 56.2442 59.3007L130.26 86.7763C132.144 87.4852 133.681 88.8938 134.551 90.7083C135.422 92.5229 135.558 94.6033 134.932 96.516C134.306 98.4287 132.966 100.026 131.192 100.975C129.417 101.924 127.344 102.151 125.406 101.609V101.609Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M64.3234 84.346L67.9603 132.794C68.1143 136.202 64.9245 139.181 60.7465 139.579C56.5685 139.978 52.894 137.592 52.4206 134.214L41.7314 71.0947"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M75.4215 147.008H45.3682V162.04H75.4215V147.008Z"
			stroke="#141414"
			strokeWidth="8.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);

export default IndustrialIcon;
