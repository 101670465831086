import { Box, SxProps } from '@mui/material';
import { memo } from 'react';

type SizeType = 'small' | 'medium' | 'large';

interface CountryFlagProps {
	countryCode: string;
	size?: SizeType;
	sx?: SxProps;
}

const getSize = (size: SizeType) => {
	switch (size) {
		case 'small':
			return 3;
		case 'medium':
			return 4;
		case 'large':
			return 5;
		default:
			return 2.5;
	}
};

const CountryFlag = ({
	countryCode,
	size = 'medium',
	sx = [],
}: CountryFlagProps): JSX.Element => (
	<Box
		component="span"
		sx={[
			(theme) => ({
				marginRight: 0.5,
				img: {
					border: `1px solid ${theme.palette.grey[100]}`,
					verticalAlign: 'bottom',
					width: theme.spacing(getSize(size)),
				},
			}),
			...(Array.isArray(sx) ? sx : [sx]),
		]}>
		<img
			loading="lazy"
			alt={countryCode}
			src={`${
				import.meta.env.BASE_URL
			}flags/${countryCode.toLowerCase()}.png`}
		/>
	</Box>
);

export default memo(
	CountryFlag,
	({ countryCode }, { countryCode: nextCountryCode }) =>
		countryCode === nextCountryCode
);
