import { SvgIcon, SvgIconProps } from '@mui/material';

const Geopin = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.5 9.4043C5.5 5.8984 8.35904 3.00195 12 3.00195C15.6344 3.00195 18.4843 5.81211 18.4999 9.37474C18.3543 10.5644 17.9986 11.6746 17.4392 12.6404L17.4384 12.6419L12.8571 20.4177C12.6074 20.7816 12.2926 21.002 11.9247 21.002C11.5187 21.002 11.215 20.8144 10.993 20.4927L6.33533 12.4926L6.33146 12.4859L6.3274 12.4794C5.78154 11.6048 5.5 10.5118 5.5 9.4043ZM12 2.00195C7.813 2.00195 4.5 5.33988 4.5 9.4043C4.5 10.6691 4.81926 11.9484 5.4749 13.0022L10.1378 21.0113L10.1463 21.0259L10.1557 21.0398C10.535 21.6008 11.1318 22.002 11.9247 22.002C12.7571 22.002 13.3426 21.485 13.6938 20.9656L13.7025 20.9527L13.7104 20.9394L18.3018 13.1464L18.3018 13.1464L18.3036 13.1433C18.9433 12.0394 19.3388 10.7855 19.4965 9.46353L19.5 9.43402V9.4043C19.5 5.26218 16.1835 2.00195 12 2.00195ZM9.5 9.50195C9.5 8.12124 10.6193 7.00195 12 7.00195C13.3807 7.00195 14.5 8.12124 14.5 9.50195C14.5 10.8827 13.3807 12.002 12 12.002C10.6193 12.002 9.5 10.8827 9.5 9.50195ZM12 6.00195C10.067 6.00195 8.5 7.56896 8.5 9.50195C8.5 11.4349 10.067 13.002 12 13.002C13.933 13.002 15.5 11.4349 15.5 9.50195C15.5 7.56896 13.933 6.00195 12 6.00195Z"
		/>
	</SvgIcon>
);

export default Geopin;
