import { toLower } from 'lodash';

import {
	AttachmentFileSet,
	PositionDto,
	ProductIndividualChoice,
	ProductIndividualMatch,
	UnitMeasurement,
} from 'api';
import {
	DimensionsType,
	convertWeightToTonnes,
} from 'api/responses/models/Unit/UnitMeasurement';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { customBrandKey, customModelKey } from 'domain/unit/brandAndModel';
import { mapFileUploadStateToAttachmentFileSet } from 'library/redux/fileUpload';
import { UnitDataFormTypes } from 'modules/Shared/State/unit';

import { guidedRegistrationFileUploadState } from './FilesSlice';
import { DEFAULT_DIMENSIONS_TYPE } from './Slice';
import { UnitOtherPartDtoGR } from './Types';

export const mapUnitDtoToUnitDataFormTypes = (
	unitInfo: Unit,
	unitMeasurement: UnitMeasurement | null | undefined
): UnitDataFormTypes => {
	const { unitOfWeight, weight } = convertWeightToTonnes(
		unitMeasurement?.weight,
		unitMeasurement?.unitOfWeight
	);

	return {
		unitName: unitInfo.identification?.name,
		registrationNumber: unitInfo.identification?.registrationNumber || '',
		machineId: unitInfo.identification?.hullMachineId,
		hullIdStandard: unitInfo.identification?.hullIdStandard,
		hullId: unitInfo.identification?.hullId,
		imoNumber: unitInfo.identification?.imoNumber,
		mmsiNumber: unitInfo.identification?.mmsiNumber,
		callSign: unitInfo.identification?.callSign,
		brand: { id: customBrandKey, label: '' },
		customBrand: unitInfo.brandOrType,
		model: { id: customModelKey, label: '' },
		customModel: unitInfo.model,
		dimensionsType:
			(unitMeasurement?.weightType as DimensionsType) ??
			DEFAULT_DIMENSIONS_TYPE,
		length: unitMeasurement?.overallLength?.toString() ?? '',
		lengthUnit: unitMeasurement?.unitOfLength ?? '',
		width: unitMeasurement?.width?.toString() ?? '',
		widthUnit: unitMeasurement?.unitOfWidth ?? '',
		displacement: weight?.toString() ?? '',
		displacementUnit: unitOfWeight ?? '',
		unitPurpose: unitInfo.consumerUsingPurpose || '',
		lineOfBusiness: toLower(unitInfo.consumerLineOfBusiness || ''),
	};
};

export const mapGuidedRegistrationFiles = (
	unitFiles: guidedRegistrationFileUploadState
): AttachmentFileSet => {
	const files = mapFileUploadStateToAttachmentFileSet(unitFiles);

	return {
		profilePicturePath: unitFiles.profilePicturePath?.serverName ?? null,
		folderName: files.folderName ?? null,
		uploadedFiles: files.uploadedFiles ?? [],
	};
};

export const mapPIMatchToPIChoice = (
	{ id }: ProductIndividualMatch,
	idTemporary: string
) =>
	({
		positionId: idTemporary,
		productIndividualId: id,
	}) as ProductIndividualChoice;

export const mapPartForSaleMatchToUnitOtherPartDtoGR = (
	position: PositionDto
): UnitOtherPartDtoGR => {
	const blueprintPFS = position.blueprintPartForSale;
	if (blueprintPFS == null) {
		throw new Error('Missing blueprintPartForSale');
	}

	const otherPart: UnitOtherPartDtoGR = {
		positionId: position.idTemporary,
		partNumber: blueprintPFS.partNumber,
		delivered: blueprintPFS.quantity,
		installed: blueprintPFS.quantity,
		description: blueprintPFS.defaultDescription,
		serialNumber: null,
		scipNumber: null,
	};

	if (position.matchedSerializedPartForSale == null) {
		return otherPart;
	} else {

		// matchedSerializedPartForSale normally will not start with SN and scipNumber,
		// but if the user chooses an SN, goes to the next screen, but then goes back - 
		// matchedSerializedPartForSale may have them
		const matchedPart = position.matchedSerializedPartForSale;
		
		return {
			...otherPart,
			serialNumber: matchedPart.serialNumber,
			scipNumber: matchedPart.scipNumber,
			candidates: blueprintPFS.candidateSerializedPartForSales.map(
				(match) => ({
					id: match.id,
					serialNumber: match.serialNumber!,
					scipNumber: match.scipNumber,
				})
			),
		};
	}
};
