import {
	Alert,
	AlertColor,
	AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { noop } from 'lodash';
import { ReactElement } from 'react';

import { BlockedDoubleClickLoadingButton } from '../buttons/BlockedDoubleClickLoadingButton';

export interface CustomSeverityDialogProps {
	acceptButtonLabel: string;
	content: string | ReactElement;
	declineButtonLabel?: string;
	disabledAcceptButton?: boolean;
	disabledDeclineButton?: boolean;
	headerText: string;
	isOpen: boolean;
	onAccept: () => void;
	onDecline?: () => void;
	severity?: AlertColor;
	loading?: boolean;
}

export const CustomSeverityDialog = ({
	acceptButtonLabel,
	content,
	declineButtonLabel,
	disabledAcceptButton = false,
	disabledDeclineButton = false,
	headerText,
	isOpen,
	onAccept,
	onDecline = noop,
	severity = 'warning',
	loading = false,
}: CustomSeverityDialogProps): JSX.Element => {
	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	return (
		<Dialog
			open={isOpen}
			fullScreen={fullScreen}
			sx={{ whiteSpace: 'pre-line' }}
			onClose={disabledDeclineButton ? noop : onDecline}>
			<DialogContent>
				<Alert severity={severity}>
					<AlertTitle variant="h6">{headerText}</AlertTitle>
					{content}
				</Alert>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: declineButtonLabel
						? 'space-between'
						: 'center',
				}}>
				{declineButtonLabel && (
					<Button
						variant="outlined"
						color="secondary"
						disableElevation
						disabled={disabledDeclineButton}
						onClick={onDecline}>
						{declineButtonLabel}
					</Button>
				)}
				<BlockedDoubleClickLoadingButton
					variant="contained"
					color="secondary"
					disableElevation
					disabled={disabledAcceptButton}
					onClick={onAccept}
					loading={loading}>
					{acceptButtonLabel}
				</BlockedDoubleClickLoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default CustomSeverityDialog;
