import {
	Box,
	CardContent,
	CardHeader,
	IconButton,
	Typography,
	keyframes,
} from '@mui/material';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import OperatingHours from 'assets/volvoIcons/ProductIndividual/OperatingHours';
import { CampaignType } from 'domain/campaign';
import {
	InstallationDrivelineId,
	ProductId,
	ProductIndividualType,
} from 'domain/unit';
import { IControl, UnitCreationCard } from 'library/components';
import { Disable, Readonly, Removable } from 'library/types';
import { PCTheme, textOverflowSx } from 'library/utils/themes/PCTheme';
import { productIndividualCampaigns } from 'main/routes/urls/RouteUrls';
import { ProductIndividualLink } from 'modules/ProductIndividualview/Components';
import { CampaignWarning } from 'modules/Shared/Components/CampaignWarning';

import { ProductIndividualCardSNField } from './ProductIndividualCardSNField';

const blink = keyframes(`
	from { border-color: #1c6bba; background: #004fbc14; }
	to { border-color: #0000001f; }
`);

const {
	palette: { grey },
} = PCTheme;

export interface ProductIndividualCardActionProps
	extends Disable,
		Readonly,
		InstallationDrivelineId,
		ProductId {
	generatedUuid: string;
	isForeign?: boolean;
}

export interface ProductIndividualCardProps extends IControl, Removable {
	actions?: ReactNode;
	campaignType?: CampaignType | null;
	designation: string | null;
	generatedUuid: string;
	isErrored?: boolean;
	isFromSearchRequest?: boolean;
	operatingHours?: number | null;
	operatingHoursDate?: string | null;
	productName: string | null;
	productType: ProductIndividualType;
	serialNumber: string;
	SNField?: ReactNode;
}

export const ProductIndividualCard = memo(
	({
		actions,
		campaignType,
		designation,
		generatedUuid,
		isErrored,
		isFromSearchRequest,
		isRemoved,
		operatingHours,
		operatingHoursDate,
		productName,
		productType,
		serialNumber,
		SNField,
	}: ProductIndividualCardProps): JSX.Element => {
		const { t } = useTranslation(['common', 'commonButton', 'productType']);

		return (
			<UnitCreationCard
				sx={[
					isFromSearchRequest
						? { animation: `${blink} 2s linear 1` }
						: null,
					isErrored
						? ({
								palette: {
									error: { main },
								},
							}) => ({
								borderColor: main,
							})
						: null,
					isRemoved ? { pointerEvents: 'none' } : null,
					{
						backgroundColor: isRemoved ? grey[50] : 'default',
						maxWidth: 20,
					},
				]}
				variant="outlined"
				color="default"
				data-testid={`PI-${generatedUuid}`}>
				<CardHeader
					action={
						<>
							{campaignType && (
								<IconButton>
									<ProductIndividualLink
										section={productIndividualCampaigns}
										serialNumber={serialNumber}
										sx={{ lineHeight: 0.5 }}>
										<CampaignWarning
											campaignType={campaignType}
											key={`action-campaign-${serialNumber}`}
										/>
									</ProductIndividualLink>
								</IconButton>
							)}
							{actions || <></>}
						</>
					}
					sx={{
						'.MuiCardHeader-action': {
							display: 'flex',
							alignItems: 'center',
						},
					}}
					title={
						<Typography
							variant="h6"
							component={'span'}
							sx={{
								fontSize: 16,
							}}>
							{t(`productType:${productType?.toString()}`, {
								defaultValue: 'error-unexpected',
							})}
						</Typography>
					}
				/>
				<CardContent>
					{SNField || (
						<ProductIndividualCardSNField
							serialNumber={serialNumber}
						/>
					)}
					<Typography
						component="p"
						variant="subtitle2"
						sx={[
							{
								fontWeight: 400,
								...textOverflowSx,
							},
						]}>
						{`${t('common:designation')}: `}
						<strong>{designation}</strong>
					</Typography>
					<Typography
						component="p"
						variant="subtitle2"
						sx={[
							{
								fontWeight: 400,
								...textOverflowSx,
							},
						]}>
						{`${t('common:product-name')}: `}
						<strong>{productName}</strong>
					</Typography>

					{!!operatingHours && operatingHours > 0 && (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}>
							<OperatingHours fontSize="medium" />
							<Typography
								component={'span'}
								variant="subtitle2"
								sx={{
									opacity: 0.6,
									ml: 1,
									fontSize: 14,
								}}>
								{operatingHours}
							</Typography>

							{operatingHoursDate && (
								<Typography
									component={'span'}
									variant="body2"
									sx={{
										opacity: 0.6,
										ml: 1,
										fontSize: 14,
									}}>
									{operatingHoursDate}
								</Typography>
							)}
						</Box>
					)}
				</CardContent>
			</UnitCreationCard>
		);
	}
);
