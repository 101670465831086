import { useQuery } from '@tanstack/react-query';

import { EndConsumerDto, useProductCenterApi } from 'api';
import { countryInfosQuery } from 'api/queries';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

export type ConsumerUnitConnectionType = 'Owner' | 'Operator';

export type EndCustomerDto = EndConsumerDto;

export function getFullName(customer: EndCustomerDto | null | undefined) {
	if (customer == null) {
		return '';
	}

	return `${customer.firstName || ''} ${customer.surNameOrLastName || ''}`;
}

export const usePhoneNumberWithPrefix = (
	customer: EndCustomerDto | null | undefined
) => {
	const { api } = useProductCenterApi();
	const { language } = useAppSelector(selectUser);
	const { data } = useQuery({
		...countryInfosQuery(api, language),
		enabled: !!customer,
	});

	if (customer == null) {
		return '';
	}

	const { phoneNumber, phonePrefix } = customer;

	return !phonePrefix
		? phoneNumber || ''
		: `+${data?.find(({ alpha2 }) => alpha2 === phonePrefix)?.dialCode || phonePrefix} ${phoneNumber || ''}`;
};

export const hasOwnerAndOperatorSameEmail = (
	owner: EndCustomerDto | null,
	operator: EndCustomerDto
) =>
	owner?.isConsumerAgreeForPentaStorageOfUserInformation === true &&
	operator.isConsumerAgreeForPentaStorageOfUserInformation &&
	owner.email === operator.email;
