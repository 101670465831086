import { createAsyncThunk } from '@reduxjs/toolkit/react';
import { AxiosResponse } from 'axios';

import { UnitRegistrationModelGR, productCenterApi } from 'api';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';
import {
	ProductIndividualSearchNoResults,
	mapFromUnitRegistrationModelGRToProductIndividualSearchResult,
} from 'modules/Shared/State/unit';

import { mapGuidedRegistrationFiles } from './Mappers';
import { GuidedRegistrationCombineType } from './Types';
import { mapRegistrationTreeModelToCommand } from './entitiesMappers';

const validateGuidedRegistrationSearchIndividualResponse = (
	requestedSerialNumber: string,
	{
		data: { infoSource, responseType },
		status,
	}: AxiosResponse<UnitRegistrationModelGR, any>
): ProductIndividualSearchNoResults | null => {
	if (status === 204) {
		return {
			errorMessage: 'unitCreation:api-error-product-not-found',
			serialNumber: null,
		};
	}

	if (
		responseType === 'InvalidIndividualStatus' &&
		(infoSource === 'PC' || !infoSource)
	) {
		return {
			errorMessage: 'unitCreation:api-error-product-status-invalid',
			serialNumber: requestedSerialNumber,
		};
	} else if (
		responseType === 'InvalidConfiguration' ||
		responseType === 'TemporaryDriveline'
	) {
		return {
			errorMessage:
				'unitCreation:api-error-product-invalid-configuration',
			serialNumber: null,
		};
	}

	return null;
};

export const searchIndividual = createAsyncThunk(
	'guidedRegistration/searchIndividual',
	async (serialNumber: string, { rejectWithValue }) => {
		try {
			const response = await productCenterApi.searchIndividual({
				serialNumber,
			});
			const { data } = response;

			// TODO: a happy path only - add actual blueprint validation in a further ticket
			const validationError =
				validateGuidedRegistrationSearchIndividualResponse(
					serialNumber,
					response
				);

			if (validationError) {
				return rejectWithValue(validationError);
			}

			// TODO: remove after fully switch to GR #874908
			const manual =
				mapFromUnitRegistrationModelGRToProductIndividualSearchResult(
					data,
					serialNumber
				);

			return {
				manual,
				gr: data,
			} as GuidedRegistrationCombineType;
		} catch (_err) {
			return rejectWithValue({ errorMessage: 'common:error-unexpected' });
		}
	}
);

export const submitUnitRegistration = createWizardSubmitThunk(
	'guidedRegistration/submitUnitRegistration',
	({ guidedRegistration, guidedRegistrationFiles }) => {
		if (!guidedRegistration.model) {
			throw new Error('Should be called only, when model was provided.');
		}

		return productCenterApi.registerUnitTree({
			rootPosition: mapRegistrationTreeModelToCommand(guidedRegistration),
			attachmentInfo: mapGuidedRegistrationFiles(guidedRegistrationFiles),
			hasOutOfCalloffMatches:
				guidedRegistration.model.hasOutOfCalloffMatches,
		});
	}
);

export const resetGuidedRegistrationState = createResetStateThunk(
	'guidedRegistration/resetState',
	(s) => s.guidedRegistrationFiles
);
