import { SvgIcon, SvgIconProps } from '@mui/material';

const ManufacturedIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="M1 26.5V1.5H5.60701V15.6679L12.1847 11.5V15.6679L18.7654 11.5V15.6679L26 11.5073V26.5H1Z"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);

export default ManufacturedIcon;
