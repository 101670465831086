import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridRowIdGetter,
	GridSlots,
	GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import {
	ForwardedRef,
	MutableRefObject,
	ReactElement,
	Ref,
	forwardRef,
	memo,
	useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { UnitOtherPartDto } from 'api';
import { DataGridToolbarWithCsvExport } from 'library/components/controls/datagrid/DataGridToolbar';
import { LocalizedDataGrid } from 'library/components/controls/datagrid/LocalizedDataGrid';
import { toPrecision } from 'library/formatters';
import { Readonly } from 'library/types';
import { ADD_ROW_ANIMATION_CLASS_NAME } from 'library/utils/themes/PCTheme';
import Variables from 'library/utils/themes/Variables';
import {
	InstalledCellEvents,
	MemoizedInstalledCell,
} from 'modules/UnitCreation/WizardSteps/OtherPartsStep/InstalledCell';

interface OtherPartColumnsProps extends InstalledCellEvents, Readonly {
	isEditMode: boolean;
}

interface CustomOtherPartColumnsProps<T extends UnitOtherPartDto>
	extends OtherPartColumnsProps {
	SNCellRenderItem?: (
		param: GridRenderCellParams<T, any, any, GridTreeNodeWithRender>
	) => JSX.Element;
}

export const useOtherPartColumns = <T extends UnitOtherPartDto>({
	isEditMode,
	isReadonly,
	onInstalledCellChanged,
	SNCellRenderItem,
}: CustomOtherPartColumnsProps<T>) => {
	const { t } = useTranslation('unitCreation');

	const columns: GridColDef<T>[] = useMemo(
		() => [
			{
				field: 'partNumber',
				headerName: t('other-parts-part-number'),
				headerAlign: 'left',
				align: 'left',
				flex: 2,
				minWidth: 130,
			},
			{
				field: 'serialNumber',
				headerName: t('other-parts-serial-number'),
				headerAlign: 'left',
				align: 'left',
				flex: 2,
				minWidth: 130,
				renderCell: SNCellRenderItem,
			},
			{
				field: 'description',
				headerName: t('other-parts-description'),
				headerAlign: 'left',
				align: 'left',
				flex: 4,
				minWidth: 350,
			},
			{
				field: 'scipNumber',
				headerName: t('other-parts-scip-number'),
				headerAlign: 'left',
				align: 'left',
				flex: 4,
				minWidth: 330,
			},
			{
				field: 'delivered',
				headerName: t('other-parts-delivered'),
				type: 'number',
				valueFormatter: (value) => toPrecision(value),
				headerAlign: 'left',
				align: 'left',
				width: 130,
			},
		],
		[t]
	);

	const editColumn: GridColDef<T> = useMemo(
		() => ({
			field: 'installed',
			headerName: t('other-parts-installed'),
			width: 130,
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			sortable: false,
			valueFormatter: (value) => toPrecision(value),
			renderCell: ({ value, ...cellRest }) => (
				<>
					{isEditMode ? (
						<MemoizedInstalledCell
							{...cellRest}
							isReadonly={isReadonly}
							value={value}
							onInstalledCellChanged={onInstalledCellChanged}
						/>
					) : (
						<Typography>{value}</Typography>
					)}
				</>
			),
			display: 'flex',
		}),
		[isEditMode, isReadonly, onInstalledCellChanged, t]
	);

	return [...columns, editColumn];
};

export interface ProductUnitDataGridProps<T extends UnitOtherPartDto>
	extends OtherPartColumnsProps {
	columns?: GridColDef<T>[];
	containerProps?: BoxProps;
	otherParts: T[];
	slots?: Partial<GridSlots>;
	getRowId?: GridRowIdGetter<T>;
}

const ProductUnitDataGrid = <T extends UnitOtherPartDto>(
	{
		columns = [],
		containerProps,
		isEditMode,
		otherParts,
		getRowId,
		slots = {
			toolbar: DataGridToolbarWithCsvExport as GridSlots['toolbar'],
		},
	}: ProductUnitDataGridProps<T>,
	ref: ForwardedRef<GridApiPro>
): JSX.Element => {
	const theme = useTheme<Variables>();
	const originParts = useMemo(() => otherParts, []);

	return (
		<Box
			{...containerProps}
			sx={[
				{
					display: 'flex',
					minWidth: 0,
					height: theme.custom.dataGridHeight,
				},
			]}>
			<LocalizedDataGrid
				apiRef={ref as MutableRefObject<GridApiPro>}
				initialState={
					isEditMode
						? { pinnedColumns: { right: ['installed'] } }
						: undefined
				}
				rows={otherParts}
				columns={columns}
				slots={slots}
				getRowId={getRowId}
				disableColumnResize
				disableColumnReorder
				disableRowSelectionOnClick
				getRowClassName={({ isFirstVisible }) =>
					isEditMode &&
					originParts.length !== otherParts.length &&
					isFirstVisible
						? ADD_ROW_ANIMATION_CLASS_NAME
						: ''
				}
			/>
		</Box>
	);
};

export type ProductUnitDataGridPropsWithRefProps<T extends UnitOtherPartDto> =
	ProductUnitDataGridProps<T> & {
		mRef?: ForwardedRef<GridApiPro>;
	};

export const ProductUnitDataGridWithRef = forwardRef(ProductUnitDataGrid) as <
	T extends UnitOtherPartDto,
>(
	p: ProductUnitDataGridPropsWithRefProps<T> & { ref?: Ref<GridApiPro> }
) => ReactElement;

export const DefaultProductUnitDataGrid = memo(
	({
		columns,
		isEditMode,
		isReadonly,
		mRef,
		onInstalledCellChanged,
		...propsRest
	}: ProductUnitDataGridPropsWithRefProps<UnitOtherPartDto>): JSX.Element => {
		const defaultColumns = useOtherPartColumns({
			isEditMode,
			isReadonly,
			onInstalledCellChanged,
		});

		return (
			<ProductUnitDataGridWithRef
				columns={defaultColumns}
				isEditMode={isEditMode}
				isReadonly={isReadonly}
				onInstalledCellChanged={onInstalledCellChanged}
				ref={mRef}
				{...propsRest}
			/>
		);
	}
);
