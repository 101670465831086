import { Box, Drawer } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useProductCenterApi } from 'api';
import { unitCampaignsQuery, unitConnectedCustomersQuery } from 'api/queries';
import { Id } from 'domain/Id';
import {
	CloseButton,
	DrawerContainer,
	SectionWrapper,
	defaultSectionWrapperSx,
} from 'library/components/controls';
import { DialogComponentProps } from 'library/components/controls/buttons/DialogIconButton';
import { CustomerBox } from 'modules/EndCustomer/Shared/CustomerBox';
import {
	CampaignsDataGrid,
	useCampaignColumns,
} from 'modules/Shared/Components/Campaign/CampaignsDataGrid';
import { UnitInformation } from 'modules/Shared/Components/Unit/Information/UnitInformation';

import { ServiceAgreementDisplay } from './ServiceAgreementDisplay';

export interface UnitDetailViewData {
	queryAsExternal?: boolean;
}

export const UnitDetailView = ({
	data: { id, queryAsExternal = false },
	handleClose,
}: DialogComponentProps<UnitDetailViewData & Id>): JSX.Element => {
	// only create this component, when displayed, remove button from it
	const { api } = useProductCenterApi();
	const queryResult = useQuery(unitConnectedCustomersQuery(api, id));
	const columns = useCampaignColumns();

	return (
		<Drawer open anchor="right" onClose={handleClose}>
			<DrawerContainer>
				<CloseButton handleClose={handleClose} />
				<UnitInformation id={id}>
					<SectionWrapper sx={{ pb: 3 }}>
						<CampaignsDataGrid
							columns={columns}
							displayAsInnerGrid
							queryOptions={unitCampaignsQuery(
								api,
								id,
								true,
								true,
								queryAsExternal ?? false
							)}
						/>
					</SectionWrapper>
					<SectionWrapper>
						<ServiceAgreementDisplay id={id} />
					</SectionWrapper>
					<SectionWrapper
						sx={{
							...defaultSectionWrapperSx,
							gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
						}}>
						<Box>
							<CustomerBox
								customer={queryResult.data?.owner}
								isOwner
							/>
						</Box>
						<Box>
							<CustomerBox
								customer={queryResult.data?.unitManager}
								isOwner={false}
							/>
						</Box>
					</SectionWrapper>
				</UnitInformation>
			</DrawerContainer>
		</Drawer>
	);
};
