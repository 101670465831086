export const formatOrderName = (
	number: string | null | undefined,
	type: string | null | undefined
) => {
	if (number == null && type == null) {
		return '';
	}

	if (type == null) {
		return number;
	}

	return `${number}/${type}`;
};
